import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import wateringImage from './../../../../img/watering-plants.png';

const NotFound: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Page Not Found</title>
      </Helmet>

      <Container>
        <Row>
          <Col xs="12" md="6">
            <h1>
              Uh oh! <br />
              That page wasn't found.
            </h1>

            <div className="my-5">
              The page you're looking for couldn't be found. <br />
              Try visiting the <Link to={ApplicationRoutes.Root}>home page</Link>&nbsp; and navigating from there.
            </div>

            <div className="center my-5">
              <Link className="btn btn-primary" to={ApplicationRoutes.Root}>
                Go Back Home
              </Link>
            </div>
          </Col>

          <Col xs="12" md="6">
            <img src={wateringImage} className="" alt="Watering plants" style={{ width: '80%' }} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFound;
