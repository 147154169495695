import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import useUrlHelper from '../../hooks/UseUrlHelper';
import { AdvisorFilterSortOptions, sortOption_Available, sortOption_Price } from '../Pages/Advisors/AdvisorResults/AdvisorsResults';

const sortOptionText_Price = 'Lowest Price';
const sortOptionText_NextAvailable = 'Next Available';

export const SortResultsDropDown = () => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [selectedSortOption, setSelectedSortOption] = useState<AdvisorFilterSortOptions>(sortOption_Available);

  const { getQueryStringParameter, setQueryStringParameter } = useUrlHelper();

  useEffect(() => {
    // Get the initial value from the URL and set it if we need to
    let currentSortOption = getQueryStringParameter<AdvisorFilterSortOptions>('sort');
    if (currentSortOption === sortOption_Available || currentSortOption === sortOption_Price) {
    } else {
      currentSortOption = sortOption_Available;
    }

    setSelectedSortOption(currentSortOption);

    updateUrlWithSort(currentSortOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const getSelectedSortText = (): string => {
    switch (selectedSortOption) {
      case sortOption_Available:
        return sortOptionText_NextAvailable;

      case sortOption_Price:
        return sortOptionText_Price;

      default:
        return '';
    }
  };

  const handleSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    const targetAsButton = event.target as HTMLButtonElement;
    const targetValue = targetAsButton.value as AdvisorFilterSortOptions;

    setSelectedSortOption(targetValue);
    updateUrlWithSort(targetValue);
  };

  const updateUrlWithSort = (sort: AdvisorFilterSortOptions) => {
    // NOTE: We only update the URL if it's not the default sort option
    setQueryStringParameter('sort', sort !== 'available' ? sort : null);
  };

  return (
    <Dropdown color="light" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="light">
        <i className="fa-duotone fa-sort"></i>

        <span className="ms-2">Sort by: {getSelectedSortText()}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={handleSelect} value={sortOption_Available}>
          <i className="fa-regular fa-calendar"></i> {sortOptionText_NextAvailable}
        </DropdownItem>
        <DropdownItem onClick={handleSelect} value={sortOption_Price}>
          <i className="fa-solid fa-dollar-sign"></i> {sortOptionText_Price}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
