//@ts-check
﻿import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import HttpHelper from '../../../Helpers/HttpHelper';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

async function populateData() {
  const data = await HttpHelper.get('api/admin/orphanintakeforms');
  // console.log('Data:', data);
  return data;
}

export const AdminIntakeForms = () => {
  const [data, setData] = useState(null);

  React.useEffect(() => {
    populateData().then((a) => {
      setData(a);
    });
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Nectarine - Intake Forms</title>
      </Helmet>
      <Row>
        <Col>
          <h1>Intake Forms</h1>

          {!data && <LoadingSpinner message="Loading..." />}
          {data && (
            <ul>
              {data.map((item) => (
                <li key={item.id}>{item.email}</li>
              ))}
            </ul>
          )}
        </Col>
      </Row>
    </Container>
  );
};
