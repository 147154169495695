//@ts-check
﻿import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row, UncontrolledAccordion } from 'reactstrap';
import { ApplicationRoutes } from '../../../../../constants';
import HttpHelper from '../../../../Helpers/HttpHelper';
import DateHelper from '../../../../Helpers/DateHelper';
import LoadingSpinner from '../../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

async function populateData() {
  const data = await HttpHelper.get('api/advisor/payments');
  // console.log('Data:', data);
  return data;
}

export const AdvisorAccountPayments = () => {
  const [data, setData] = useState(null);

  React.useEffect(() => {
    populateData().then((a) => {
      setData(a);
    });
  }, []);

  //Make a const that's now in UTC
  const now = new Date();

  return (
    <Container>
      <Helmet>
        <title>Nectarine - Payments</title>
      </Helmet>
      <Row>
        <Col>
          {data && data.eventsIncomplete && data.eventsIncomplete.length > 0 && (
            <div>
              <h1>My Incomplete Events</h1>
              <p>
                Below are the your events that are not yet complete, either because they haven't started or because you have not yet set the
                notes to the client.
              </p>
              <UncontrolledAccordion className="accordion-primary past-meetings">
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    You have a pending &nbsp;
                    <strong>
                      $
                      {data.eventsIncomplete
                        .reduce((sum, event) => sum + event.advisorOwedAmount, 0)
                        .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </strong>
                    &nbsp;for&nbsp;<strong>{data.eventsIncomplete.length}</strong>&nbsp;incomplete events
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <table className="table advisor-events">
                      <thead>
                        <tr>
                          <th>
                            <i className="fa-duotone fa-peach light-blue"></i>
                          </th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>State</th>
                          <th>Meeting Time</th>
                          <th>Notes Sent</th>
                          <th>Amount Pending</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.eventsIncomplete.map((event) => (
                          <tr key={event.id}>
                            <td>{event.isNectarine && <i className="fa-duotone fa-peach light-blue"></i>}</td>
                            <td>
                              <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Client_Full}/${event.consumerId}`}>
                                {event.consumerLastName}, {event.consumerFirstName}
                              </Link>
                              {event.eventCount > 1 && <span> ({event.eventCount})</span>}
                            </td>
                            <td>{event.consumerEmail}</td>
                            <td>{event.consumerState}</td>
                            <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                            <td>
                              {!event.notesSent && new Date(event.startTime) < now && (
                                <strong className="text-danger">Notes not sent</strong>
                              )}
                              {!event.notesSent && new Date(event.startTime) >= now && <span>-</span>}
                              {event.notesSent && <span>{DateHelper.mediumDateFormat(event.notesSentAt)}</span>}
                            </td>
                            <td>
                              ${event.advisorOwedAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>
          )}

          {data && data.eventsDue && data.eventsDue.length > 0 && (
            <div>
              <h1>My Events Owed</h1>
              <p>
                Below are the unpaid events that are due to you. They will be included in an upcoming pay period based on when the notes
                were sent to the client.
              </p>
              <UncontrolledAccordion className="accordion-primary past-meetings">
                <AccordionItem>
                  <AccordionHeader targetId="1">
                    You are owed&nbsp;
                    <strong>
                      $
                      {data.eventsDue
                        .reduce((sum, event) => sum + event.advisorOwedAmount, 0)
                        .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </strong>
                    &nbsp;for&nbsp;<strong>{data.eventsDue.length}</strong>&nbsp;completed events
                  </AccordionHeader>
                  <AccordionBody accordionId="1">
                    <table className="table advisor-events">
                      <thead>
                        <tr>
                          <th>
                            <i className="fa-duotone fa-peach light-blue"></i>
                          </th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>State</th>
                          <th>Meeting Time</th>
                          <th>Notes Sent</th>
                          <th>Amount Owed</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.eventsDue.map((event) => (
                          <tr key={event.id}>
                            <td>{event.isNectarine && <i className="fa-duotone fa-peach light-blue"></i>}</td>
                            <td>
                              <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Client_Full}/${event.consumerId}`}>
                                {event.consumerLastName}, {event.consumerFirstName}
                              </Link>
                              {event.eventCount > 1 && <span> ({event.eventCount})</span>}
                            </td>
                            <td>{event.consumerEmail}</td>
                            <td>{event.consumerState}</td>
                            <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                            <td>{DateHelper.mediumDateFormat(event.notesSentAt)}</td>
                            <td>
                              ${event.advisorOwedAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>
          )}

          <h1>My Payments</h1>

          {!data && <LoadingSpinner message="Loading..." />}

          {data && data.payments && data.payments.length === 0 && <p>No payments have been made to you yet.</p>}
          {data && data.payments && data.payments.length > 0 && (
            <div>
              <p>
                Below is the list of past payments made to you. All time you have made{' '}
                <strong>${data.payments.reduce((sum, payment) => sum + payment.amount, 0).toLocaleString()}</strong> from{' '}
                <strong>{data.payments.reduce((sum, payment) => sum + payment.numEvents, 0).toLocaleString()}</strong> events.
              </p>

              <table className="table">
                <thead>
                  <tr>
                    <th>Payment Date</th>
                    <th>Amount</th>
                    <th>Events</th>
                  </tr>
                </thead>
                <tbody>
                  {data.payments.map((item) => (
                    <tr key={item.id}>
                      <td>{DateHelper.shortDateFormat(item.paidAt)}</td>
                      <td>${item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>{item.numEvents}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
