import React, { useState } from 'react';
import AuthorizeService from './../api-authorization/AuthorizeService';
import './../Pages/Layout/PageLayout/PageLayout.scss';

interface ImpersonationLabelProps {
  user: User_BasicDetails_ViewModel;
}

const ImpersonationLabel: React.FC<ImpersonationLabelProps> = ({ user }: ImpersonationLabelProps) => {
  const [loggedInUser, setLoggedInUser] = useState<{ name: string } | null>(null);

  React.useEffect(() => {
    const loadData = async () => {
      const userObj = await AuthorizeService.getUser();
      setLoggedInUser(userObj);
    };

    loadData();
  }, []);

  return loggedInUser && user && loggedInUser.name !== user.email ? (
    <div className="impersonate-label">
      <div>Impersonating {user.firstName}</div>
    </div>
  ) : (
    <></>
  );
};

export default ImpersonationLabel;
