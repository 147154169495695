import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes, CommonApiBadRequestErrors } from '../../../../constants';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import UsersApiService from '../../../../services/api/UsersApiService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import StateHelper from '../../../Helpers/StateHelper';
import { useLoadingOverlayContext } from '../../../LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import wateringImage from './../../../../img/watering-plants.png';

export const ThePlanTerms = () => {
  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [advisors, setAdvisors] = useState<Advisor_BasicDetails_ViewModel[] | null>(null);
  const [state, setState] = useState<string | null>(localStorage.getItem('userState') ?? null);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorSection, setErrorSection] = useState<{ show: boolean; text: string | null }>({ show: false, text: null });
  const [agreeIsChecked, setAgreeIsChecked] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthStatus = async () => {
      const isAuthenticatedResult = await AuthorizeService.isAuthenticated();
      setIsAuthenticated(isAuthenticatedResult);
    };

    getAuthStatus();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (!slug) {
        let advisorsList = await AdvisorsApiService.getAllAdvisorDetails();
        advisorsList = sortBy(advisorsList, (v) => v.firstName);

        setAdvisors(advisorsList);
      } else {
        const advisorObj = await AdvisorsApiService.getAdvisorDetailsBySlug(slug, false, false, false);
        setAdvisor(advisorObj);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onAgreeChecked(e: React.ChangeEvent<HTMLInputElement>) {
    setAgreeIsChecked(e.target.checked);
  }

  const onStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(e.target.value);

    localStorage.setItem('userState', e.target.value);
  };

  function docSeparator(i: number, length: number) {
    if (i == 0 && length == 2) return <span> and </span>;
    if (i < length - 2) return <span>, </span>;
    if (i < length - 1) return <span>, and </span>;
    return <span> </span>;
  }

  async function onAdvisorClick(e: React.MouseEvent, advisorSlug: string) {
    e.preventDefault();

    const data = await AdvisorsApiService.getAdvisorDetailsBySlug(advisorSlug, false, false, false);

    setAdvisor(data);

    navigate(`/${ApplicationRoutes.ThePlan_Terms}/${advisorSlug}`);

    return false;
  }

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function isAgreeButtonEnabled() {
    return agreeIsChecked && isAuthenticated;
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  async function onAgreeToTermsSubmit(e: React.FormEvent) {
    e.preventDefault();
    hideError();

    try {
      showLoadingOverlay();

      await UsersApiService.agreeToPlanTerms(advisor!.id, 'ThePlan', state!);

      setSuccess(true);
    } catch (error) {
      const errorObj = error as { message: string };

      if (errorObj.message === CommonApiBadRequestErrors.NotLoggedIn) {
        setIsAuthenticated(false);
        hideError();
      } else {
        console.log(errorObj.message);
        showError(ErrorsHelper.getErrorMessageFromErrorObject(errorObj.message));
      }
    } finally {
      hideLoadingOverlay();
    }

    return false;
  }

  return (
    <Container>
      <Helmet>
        <title>Nectarine - The Plan - Agree To Terms</title>
      </Helmet>
      <Row>
        <Col md="3">
          <img src={wateringImage} className="" alt="Watering plants" style={{ width: '80%' }} />
        </Col>
        {success && (
          <Col>
            <h1>Thank you!</h1>
            <p>Your agreements have been logged and you're ready for the plan. We look forward to meeting with you!</p>
            <p>You may now close this window.</p>
          </Col>
        )}
        {advisor && !success && (
          <Col md="9" className="center pt-5">
            <Form onSubmit={(e) => onAgreeToTermsSubmit(e)}>
              <h1>Nectarine Contract</h1>
              <img
                src={advisor.profilePicUrl}
                className="circle-image headshot center small-headshot"
                alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
              />
              <h3>
                {advisor.firstName} {advisor.lastName}
                {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                {advisor.certifications}
              </h3>
              <Label for="state" className="state-label">
                Which state do you live in?
              </Label>
              <select id="state" name="state" className="state-search" required onChange={onStateChange} value={state!}>
                <option value="">Which state do you live in?</option>
                {StateHelper.usStatesAndTerritories.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </select>
              <p>Please read and agree to the linked documents below so we can get started with The Plan!</p>
              <Input id="agreeToTerms" name="agreeToTerms" type="checkbox" onChange={(e) => onAgreeChecked(e)} />
              &nbsp;
              {advisor.isNectarine && (
                <Label for="agreeToTerms" className="ms-2">
                  I agree to Nectarine's
                  {advisor.nectarinePlanComplianceDocuments.map((currDocument, i) => (
                    <span key={i}>
                      {' '}
                      <a href={currDocument.url} target="_blank" rel="noopener noreferrer">
                        {currDocument.displayName}
                      </a>
                      {docSeparator(i, advisor.nectarinePlanComplianceDocuments.length)}
                    </span>
                  ))}
                </Label>
              )}
              {!isAuthenticated ? (
                <div>
                  <Alert color="warning" className="mt-4">
                    You are not currently logged in.
                    <br />
                    Please
                    <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}> log in</Link> or
                    <Link to={`/${ApplicationRoutes.Register}`}> create a new account</Link> to continue.
                  </Alert>
                </div>
              ) : (
                <></>
              )}
              {errorSection.show && (
                <Alert color="danger" className="mt-4">
                  {errorSection.text}
                </Alert>
              )}
              <p className="center mt-4">
                <Button disabled={!isAgreeButtonEnabled()} color="primary" size="lg" className="schedule-btn">
                  I Agree
                </Button>
              </p>
            </Form>
          </Col>
        )}
        {!advisor && advisors && (
          <Col>
            <h1>Choose Your Advisor</h1>
            <ul>
              {advisors.map((advisor) => (
                <li key={advisor.id}>
                  <a href="#" onClick={(e) => onAdvisorClick(e, advisor.slug)}>
                    {advisor.firstName} {advisor.lastName}
                    {advisor.certifications !== null && advisor.certifications !== undefined && advisor.certifications !== '' ? ', ' : ''}
                    {advisor.certifications}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        )}
        {!advisor && !advisors && (
          <Col>
            <LoadingSpinner />
          </Col>
        )}
      </Row>
    </Container>
  );
};
