import { useEffect, useRef } from 'react';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

type UseObservableReturnValue<T> = [Observable<T>, (value: T) => void, () => T];

// A subject does NOT emit its current value to code subscribing to it
function useObservableWithSubject<T>(): UseObservableReturnValue<T> {
  // NOTE: These underlying variables are all stable and do not change, so we
  // can use useRef instead of useState, since we aren't triggering
  // rerenders through state changes
  const subject = useRef(new Subject<T>());
  const lastValue = useRef<T>();

  const getValue = (): T => {
    return lastValue.current!;
  };

  // A method to set the next value of the observable
  const setValue = (value: T) => {
    subject.current.next(value);

    lastValue.current = value;
  };

  // Make sure to clean up the subject when the hook is unmounted
  useEffect(() => {
    const currBehaviorSubject = subject.current;

    // Return a cleanup function
    return () => currBehaviorSubject.complete();
  }, [subject]);

  // Return the observable (as read-only) and the method to set the value
  return [subject.current.asObservable(), setValue, getValue];
}

// A behavior subject emits its current value immediately upon any code subscribing to it
function useObservableWithBehaviorSubject<T>(initialValue: T): UseObservableReturnValue<T> {
  // NOTE: These underlying variables are all stable and do not change, so we
  // can use useRef instead of useState, since we aren't triggering
  // rerenders through state changes
  const behaviorSubject = useRef(new BehaviorSubject<T>(initialValue));

  const getValue = (): T => {
    return behaviorSubject.current.value;
  };

  // A method to set the next value of the observable
  const setValue = (value: T) => {
    behaviorSubject.current.next(value);
  };

  // Make sure to clean up the subject when the hook is unmounted
  useEffect(() => {
    const currBehaviorSubject = behaviorSubject.current;

    // Return a cleanup function
    return () => currBehaviorSubject.complete();
  }, [behaviorSubject]);

  // Return the observable (as read-only) and the method to set the value
  return [behaviorSubject.current.asObservable(), setValue, getValue];
}

export { useObservableWithBehaviorSubject, useObservableWithSubject };
