import React from 'react';
import DateHelper from '../Helpers/DateHelper';
import TypeformParser from '../Helpers/TypeformParser';

interface IntakeResponsesListingProps {
  clientFirstName: string;
  intakeResponseData: ClientIntakeResponseJsonData;
  intakeResponseSubmitDate: Date;
}

const IntakeResponsesListing: React.FC<IntakeResponsesListingProps> = ({
  clientFirstName,
  intakeResponseData,
  intakeResponseSubmitDate
}: IntakeResponsesListingProps) => {
  if (!intakeResponseData) {
    return <></>;
  }

  return (
    <div className="intake-form">
      <p>
        <i className="fa-duotone fa-check-to-slot att-icon"></i> {clientFirstName} submitted this form on
        <strong> {DateHelper.longDateFormat(intakeResponseSubmitDate)}</strong>
      </p>
      <ul>
        {intakeResponseData.form_response.definition.fields.map((item, index) => (
          <li key={index}>
            <b>{item.title}</b>
            <br />
            {TypeformParser.formatAnswer(intakeResponseData.form_response.answers[index])}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IntakeResponsesListing;
