//@ts-check
﻿import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ComplianceReportContent } from '../../../ComplianceReportContent/ComplianceReportContent';
import HttpHelper from '../../../Helpers/HttpHelper';

async function populateComplianceReport(userID) {
  const data = await HttpHelper.get('api/users/' + userID + '/report');
  // console.log('Client:', data);
  return data;
}

export const AdminComplianceReport = () => {
  let { userID } = useParams();

  const [complianceReport, setComplianceReport] = useState(null);

  React.useEffect(() => {
    const loadData = async () => {
      const result = await populateComplianceReport(userID);
      setComplianceReport(result);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ComplianceReportContent complianceReport={complianceReport} />
    </Container>
  );
};
