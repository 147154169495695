//@ts-check
﻿import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  Editor,
  EditorProvider,
  HtmlButton,
  Separator,
  Toolbar
} from 'react-simple-wysiwyg';
import { Alert, Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import DateHelper from '../../../../Helpers/DateHelper';
import HttpHelper from '../../../../Helpers/HttpHelper';
import LoadingDots from './../../../../LoadingAnimations/LoadingDots/LoadingDots';

export const EnterMeetingNotesModal = (props) => {
  const [event, setEvent] = useState(getEvent());
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [saveLoading, setSaveLoading] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  React.useEffect(() => {
    setEvent(getEvent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.event]);

  React.useEffect(() => {
    if (event) {
      saveEventToLocal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  function getStoredEventKey(id) {
    return 'EventNotes-' + id;
  }

  function getStoredEvent(id) {
    return JSON.parse(localStorage.getItem(getStoredEventKey(id)));
  }

  function saveEventToLocal() {
    // Get the current time in UTC
    const nowUTC = new Date().toISOString();

    event.notesUpdated = nowUTC;

    localStorage.setItem(getStoredEventKey(event.id), JSON.stringify(event));
  }

  function clearLocalEvent() {
    localStorage.removeItem(getStoredEventKey(event.id));
  }

  function getEvent() {
    if (!props.event) return null;

    const storedEvent = getStoredEvent(props.event.id);

    if (storedEvent && storedEvent.notesUpdated > props.event.notesUpdated) {
      return storedEvent;
    }

    return props.event;
  }

  function stripStyles(html) {
    html = addTargetBlankToLinks(html);

    // Allow only a super restricted set of tags and attributes
    return sanitizeHtml(html, {
      allowedTags: ['b', 'i', 'u', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'br', 'div', 'strike'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    });
  }

  function addTargetBlankToLinks(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const anchorElements = tempDiv.querySelectorAll('a');

    anchorElements.forEach((anchor) => {
      if (!anchor.hasAttribute('target')) {
        anchor.setAttribute('target', '_blank');
      }
    });

    return tempDiv.innerHTML;
  }

  function previewMode() {
    //Strip styles from the notes
    setEvent((a) => ({
      ...a,
      ['publicNotes']: stripStyles(a.publicNotes),
      ['privateNotes']: stripStyles(a.privateNotes)
    }));

    setIsPreviewMode(true);
  }

  function onFormChange(e) {
    hideError();
    setEvent((a) => ({
      ...a,
      [e.target.name]: e.target.value
    }));
  }

  const onSaveWithoutSending = async (e) => {
    sendToServer(e, '/api/advisor/savenotes/' + event.id);
  };

  const saveAndSend = async (e) => {
    sendToServer(e, '/api/advisor/sendnotes/' + event.id);
  };

  const sendToServer = async (e, url) => {
    e.preventDefault();

    setSaveLoading(true);

    var dto = {
      id: event.id,
      privateNotes: event.privateNotes,
      publicNotes: event.publicNotes
    };

    HttpHelper.put(url, dto)
      .then(() => {
        setSaveLoading(false);
        props.save(event);
        clearLocalEvent();
        closeModal();
      })
      .catch((error) => {
        showError(error);
        setSaveLoading(false);
      });

    return false;
  };

  function showError(text) {
    let errorSection = {};

    errorSection.show = true;
    errorSection.text = text.toString();

    setErrorSection(errorSection);
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  function closeModal() {
    setIsPreviewMode(false);
    props.toggle();
  }

  return (
    <Modal isOpen={props.show} toggle={closeModal}>
      {event && (
        <Form onSubmit={saveAndSend}>
          <ModalHeader toggle={closeModal}>
            Meeting Notes for <strong>{event.consumerFullName}</strong> on <strong>{DateHelper.mediumDateFormat(event.startTime)}</strong>
          </ModalHeader>
          <ModalBody>
            {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

            {isPreviewMode && (
              <div>
                <div className="private-notes">
                  <h3>Private notes (not visible to client):</h3>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.privateNotes) }}></div>
                </div>
                {!event.notesSent && (
                  <div>
                    <hr />
                    <h3>Wrap-up email that will be sent to {event.consumerFullName}</h3>
                    <p className="email-header">
                      <strong>To:</strong> {event.consumerFullName} &lt;{event.consumerEmail}&gt;
                      <br />
                      <strong>From:</strong> Nectarine Team &lt;team@hellonectarine.com&gt;
                      <br />
                      <strong>Bcc:</strong> {event.advisorFullName} &lt;{event.advisorEmail}&gt;
                      <br />
                    </p>
                    <hr className="hr-light" />
                    <p className="email-header">
                      <strong>Subject:</strong> Your Nectarine Meeting Notes from {event.advisorFullName} -{' '}
                      {DateHelper.shortDateFormat(event.startTime)}
                      <br />
                    </p>
                    <hr className="hr-light" />
                    <p>Hi {event.consumerFirstName},</p>
                    <p>
                      Thanks so much for meeting with Nectarine! Below are notes and takeaways from your advisor, {event.advisorFullName}:
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.publicNotes, { ADD_ATTR: ['target'] }) }}></p>
                    <hr />
                    <p>How was your meeting with {event.advisorFirstName}? Please take a moment to leave a review.</p>
                    <p>
                      <a className="btn btn-light">Leave a review for {event.advisorFirstName}</a>
                    </p>
                    <p>Do you have more questions or want to follow up with {event.advisorFirstName}?</p>
                    <p>
                      <a className="btn btn-light">Book another meeting with {event.advisorFirstName}</a>
                    </p>

                    <p>Until next time!</p>
                    <p>
                      Nectarine Team
                      <br />
                      <a href="https://hellonectarine.com">hellonectarine.com</a>
                    </p>
                  </div>
                )}
              </div>
            )}
            {!isPreviewMode && (
              <div>
                <FormGroup>
                  <Label for="privateNotes">Private Notes (Not Visible to Client)</Label>
                  <EditorProvider>
                    <Editor
                      id="privateNotes"
                      name="privateNotes"
                      required={true}
                      placeholder={'e.g. ' + event.consumerFirstName + ' is 35 years old and works as a teacher....'}
                      value={event.privateNotes}
                      containerProps={{ style: { minHeight: '200px' } }}
                      onChange={(e) => onFormChange(e)}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnStrikeThrough />
                        <Separator />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <Separator />
                        <BtnLink />
                        <HtmlButton />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </FormGroup>
                {!event.notesSent && (
                  <FormGroup>
                    <Label for="publicNotes">Meeting Notes (Shared With Client)</Label>
                    <EditorProvider>
                      <Editor
                        id="publicNotes"
                        name="publicNotes"
                        required={true}
                        placeholder={
                          'e.g. It was great talking with you today, ' +
                          event.consumerFirstName +
                          '! Here are your next steps and resources we discussed...'
                        }
                        value={event.publicNotes}
                        containerProps={{ style: { minHeight: '200px' } }}
                        onChange={(e) => onFormChange(e)}
                      >
                        <Toolbar>
                          <BtnBold />
                          <BtnItalic />
                          <BtnStrikeThrough />
                          <Separator />
                          <BtnBulletList />
                          <BtnNumberedList />
                          <Separator />
                          <BtnLink />
                          <HtmlButton />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  </FormGroup>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {!isPreviewMode && (
              <div>
                <Button color="light" onClick={closeModal}>
                  Cancel
                </Button>{' '}
                <Button
                  color="primary"
                  onClick={() => {
                    previewMode();
                    return false;
                  }}
                >
                  Preview Notes
                </Button>{' '}
              </div>
            )}
            {isPreviewMode && (
              <div>
                {!saveLoading && (
                  <span>
                    <Button
                      color="light"
                      onClick={() => {
                        setIsPreviewMode(false);
                        return false;
                      }}
                    >
                      Back
                    </Button>{' '}
                  </span>
                )}
                <Button
                  color="light"
                  disabled={saveLoading}
                  onClick={(e) => {
                    onSaveWithoutSending(e);
                    return false;
                  }}
                >
                  {saveLoading ? <LoadingDots /> : 'Save Notes Without Sending'}
                </Button>{' '}
                {!event.notesSent && (
                  <Button color="primary" disabled={saveLoading}>
                    {saveLoading ? <LoadingDots /> : 'Save and Send Email To Client'}
                  </Button>
                )}{' '}
              </div>
            )}
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};
