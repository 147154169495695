import { useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './LoadingOverlay.scss';
import { useLoadingOverlayContext } from './LoadingOverlayContext';

const LoadingOverlay: React.FC = () => {
  const allSubscriptions = useRef<Subscription>(new Subscription());
  const { loadingOverlaysCount$ } = useLoadingOverlayContext();

  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  // For performance, we utilize observables instead of state variables in a context because observables
  // are stable objects and don't trigger rerenders
  useEffect(() => {
    allSubscriptions.current.add(
      loadingOverlaysCount$.subscribe((v) => {
        setIsDisplayed(v > 0);
      })
    );

    const allSubscriptionsObj = allSubscriptions.current;

    return () => {
      allSubscriptionsObj.unsubscribe();
    };
  }, [allSubscriptions, loadingOverlaysCount$]);

  return (
    <div className={`loadingOverlay ${isDisplayed ? 'isDisplayed' : ''}`}>
      <LoadingSpinner />
    </div>
  );
};

export default LoadingOverlay;
