//@ts-check
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DateHelper from '../../../../Helpers/DateHelper';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import HttpHelper from '../../../../Helpers/HttpHelper';

export const EditCustomAttributes = (props) => {
  const [attributes, setAttributes] = useState(props.attributes);
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [newAttribute, setNewAttribute] = useState(null);

  const today = new Date();
  var minDate = new Date(0);
  var maxDate = new Date(9999, 11, 31);

  useEffect(() => {
    if (attributes.length == 0) {
      newItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveItem = async (event) => {
    event.preventDefault();

    newAttribute.startDate = DateHelper.isValidDate(newAttribute.startDateString) ? new Date(newAttribute.startDateString) : minDate;

    newAttribute.endDate = DateHelper.isValidDate(newAttribute.endDateString) ? new Date(newAttribute.endDateString) : minDate;

    if (newAttribute.current) {
      newAttribute.endDate = maxDate;
    }

    HttpHelper.put('/api/advisor/attribute', newAttribute)
      .then((newAttribute) => {
        let newAttributes = attributes.filter((item) => item.id !== newAttribute.id);
        newAttributes.push(newAttribute);

        //Sort newAttributes by title
        newAttributes.sort((a, b) => (a.title > b.title ? 1 : -1));

        setAttributes(newAttributes);
        setNewAttribute(null);
      })
      .catch((error) => {
        showError(ErrorsHelper.getErrorMessageFromErrorObject(error.message));
        console.error('Unable to add item.', error);
      });

    return false;
  };

  const deleteItem = async (event, att) => {
    event.preventDefault();

    if (!window.confirm('Are you sure you want to delete this item?')) {
      return false;
    }

    HttpHelper.delete('/api/advisor/attribute', att.id)
      .then(() => {
        //Create new attributes array without the deleted item
        const newAttributes = attributes.filter((item) => item.id !== att.id);
        setAttributes(newAttributes);
      })
      .catch((error) => {
        showError(error);
        console.error('Unable to add item.', error);
      });

    return false;
  };

  function newItem() {
    let newEducation = {};

    newEducation.title = '';
    newEducation.subtitle = '';
    newEducation.categoryID = props.categoryID;
    newEducation.iconTag = 'fa-graduation-cap';

    setNewAttribute(newEducation);
  }

  function editItem(event, item) {
    event.preventDefault();

    //For making the date picker work
    item.startDateString =
      new Date(item.startDate) > minDate ? new Date(item.startDate).toLocaleDateString('en-CA', { timeZone: 'UTC' }) : '';
    item.endDateString = new Date(item.endDate) ? new Date(item.endDate).toLocaleDateString('en-CA', { timeZone: 'UTC' }) : '';

    item.current = new Date(item.endDate) > today;

    setNewAttribute(item);
    return false;
  }

  function cancelNewItem() {
    setNewAttribute(null);
  }

  function onCurrentChanged(e) {
    hideError();
    const { name, type } = e.target;
    // Use the appropriate property based on the element type
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    var endDateString = new Date(newAttribute.endDateString);

    if (!value) {
      endDateString = new Date().toLocaleDateString('en-CA');
    }

    setNewAttribute((a) => ({
      ...a,
      [name]: value,
      endDateString: endDateString
    }));
  }

  function onFormChange(e) {
    hideError();
    const { name, type } = e.target;
    // Use the appropriate property based on the element type
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    setNewAttribute((a) => ({
      ...a,
      [name]: value
    }));
  }

  function showError(text) {
    let errorSection = {};

    errorSection.show = true;
    errorSection.text = text.toString();

    console.error('showError', text);

    setErrorSection(errorSection);
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit {props.title}</ModalHeader>
      <ModalBody>
        {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

        {newAttribute && (
          <Form>
            <Row>
              <Col md="6">
                <Label>Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  required={true}
                  maxLength="40"
                  value={newAttribute.title}
                  onChange={onFormChange}
                  placeholder="e.g. UCLA or Certified Financial Planner"
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label>Subtitle (Optional)</Label>
                <Input
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  maxLength="40"
                  value={newAttribute.subtitle}
                  onChange={onFormChange}
                  placeholder="e.g. Bachelors of Science or Since 2017"
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" md="3">
                <Label>Start Date (Optional)</Label>
                <Input
                  type="date"
                  name="startDateString"
                  id="startDateString"
                  value={newAttribute.startDateString}
                  onChange={onFormChange}
                />
              </Col>
              <Col xs="6" md="3">
                <Label>End Date (Optional)</Label>
                <br />
                {!newAttribute.current && (
                  <Input type="date" name="endDateString" id="endDateString" value={newAttribute.endDateString} onChange={onFormChange} />
                )}
                <Input type="checkbox" name="current" id="current" checked={newAttribute.current} onChange={onCurrentChanged} />{' '}
                <Label for="current">Current</Label>
              </Col>
            </Row>
            <Row>
              <Label>Icon</Label>
              <Col md="6">
                <Input
                  type="select"
                  name="iconTag"
                  id="iconTag"
                  value={newAttribute.iconTag}
                  onChange={onFormChange}
                  className="icon-select"
                >
                  <option value="fa-briefcase">Briefcase</option>
                  <option value="fa-building">Building</option>
                  <option value="fa-building-columns">Building with Columns</option>
                  <option value="fa-file-certificate">Certificate</option>
                  <option value="fa-chair-office">Chair</option>
                  <option value="fa-diploma">Diploma</option>
                  <option value="fa-money-bill">Dollar Bill</option>
                  <option value="fa-graduation-cap">Graduation Cap</option>
                  <option value="fa-school">School</option>
                </Input>
              </Col>
              <Col md="2">
                <i className={'fa-light fa-xl att-icon att-details ' + newAttribute.iconTag}></i>
              </Col>
            </Row>
          </Form>
        )}
        {!newAttribute && (
          <Row>
            {attributes.map((item) => (
              <Col key={item.id} md="12" className="education-row">
                <i className={'fa-light fa-xl att-icon att-details ' + item.iconTag}></i>
                <div className="att-caption">
                  <div className="title">{item.title}</div>
                  <div className="att-subtitle">{item.subtitle}</div>
                  <div className="att-subtitle">{DateHelper.printYearRange(item.startDate, item.endDate)}</div>
                </div>
                <Button color="danger" className="education-button" onClick={(e) => deleteItem(e, item)}>
                  Delete
                </Button>
                <Button color="light" className="education-button" onClick={(e) => editItem(e, item)}>
                  <i className="fa fa-pen-to-square"></i>
                  Edit
                </Button>
              </Col>
            ))}
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        {newAttribute && (
          <div>
            <Button color="light" onClick={cancelNewItem}>
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={saveItem}>
              Save
            </Button>
          </div>
        )}
        {!newAttribute && (
          <div>
            <Button color="success" onClick={newItem}>
              + New Item
            </Button>{' '}
            <Button color="primary" onClick={props.toggle}>
              Done
            </Button>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};
