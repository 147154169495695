import React from 'react';

export const BeforeYourMeeting: React.FC = () => {
  return (
    <div className="info-box">
      <i className="float-start fa-regular fa-lightbulb info-box-icon"></i>
      <div className="info-box-details">
        <h3>Before your meeting, you may want to:</h3>
        <ul>
          <li>
            Prepare your
            <a target="_blank" rel="noopener noreferrer" href="https://www.personalfinanceclub.com/how-to-calculate-your-net-worth/">
              &nbsp;net worth statement
            </a>
          </li>
          <li>Know your monthly income and expenses</li>
          <li>Have your investment accounts ready to log in</li>
        </ul>
      </div>
    </div>
  );
};
