//@ts-check
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { ApplicationRoutes, UserRoles } from '../../constants';
import RolesHelper from '../Helpers/RolesHelper';
import AuthorizeService from './AuthorizeService';

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      roles: null,
      userName: null
    };
  }

  componentDidMount() {
    this._subscription = AuthorizeService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    AuthorizeService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([AuthorizeService.isAuthenticated(), AuthorizeService.getUser()]);
    const roles = await RolesHelper.getRoles();
    this.setState({
      isAuthenticated,
      userName: user && user.name,
      roles: roles
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `/${ApplicationRoutes.Register}`;
      const loginPath = `/${ApplicationRoutes.AuthenticationRoutes.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `/${ApplicationRoutes.AuthenticationRoutes.Profile}`;
      const logoutPath = `/${ApplicationRoutes.AuthenticationRoutes.Logout}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, profilePath, logoutPath, logoutState) {
    if (this.props.location == 'top') {
      return (
        <Fragment>
          {this.state.roles.includes(UserRoles.Consumer) ? (
            <NavItem>
              <NavLink to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`} tag={Link} className="btn btn-light login-button">
                My Account
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )}

          {this.state.roles.includes(UserRoles.Advisor) || this.state.roles.includes(UserRoles.Admin) ? (
            <NavItem>
              <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`} tag={Link} className="btn btn-light login-button">
                Advisor Home
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )}

          {this.state.roles.includes(UserRoles.Admin) ? (
            <NavItem>
              <NavLink to={`/${ApplicationRoutes.AdminRoutes.Root}`} tag={Link} className="btn btn-light login-button">
                Admin
              </NavLink>
            </NavItem>
          ) : (
            <></>
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} to={profilePath}>
            Hello {userName}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace tag={Link} to={logoutPath} state={logoutState}>
            Logout
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }

  anonymousView(registerPath, loginPath) {
    if (this.props.location == 'top') {
      return (
        <Fragment>
          <NavItem>
            <NavLink tag={Link} className="btn btn-light login-button" to={loginPath}>
              Login
            </NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <NavItem>
          <NavLink tag={Link} to={registerPath}>
            Register
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={loginPath}>
            Login
          </NavLink>
        </NavItem>
      </Fragment>
    );
  }
}
