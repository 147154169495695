import HttpHelper from '../../components/Helpers/HttpHelper';
import { SortOptions } from '../../constants';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

const apiRouteBase = 'api/reviews';

export default class ReviewsApiService extends ApiServiceBase {
  private static className = 'ReviewsApiService';

  public static async getReviewsForCurrentUser(): Promise<Reviews_Review_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Reviews_Review_ViewModel[]>(() => HttpHelper.get(`${apiRouteBase}/reviews`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getReviewsForCurrentUser()`,
        'An error occurred while loading reviews for the current user.',
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async saveReview(
    reviewObj: Reviews_Review_EditModel,
    isNonAuthenticatedReview: boolean
  ): Promise<Reviews_Review_ViewModel> {
    try {
      const url = `${apiRouteBase}/save-review${isNonAuthenticatedReview ? '-2' : ''}`;

      const result = await this.makeApiCall<Reviews_Review_ViewModel>(
        async () => (await HttpHelper.put(url, reviewObj)) as Reviews_Review_ViewModel
      );

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - saveReview()`,
        `An error occurred while saving a review for reviewer with ID ${reviewObj.reviewerId} & reviewee with ID ${reviewObj.revieweeId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorReviewsAggregateData(advisorId: string): Promise<Advisor_ReviewsAggregateData_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_ReviewsAggregateData_ViewModel>(() =>
        HttpHelper.get(`api/reviews/get-reviews-aggregate-data/${advisorId}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorReviewsAggregateData()`,
        `An error occurred while loading advisor reviews aggregate data for advisor ID: ${advisorId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorReviewsData(
    advisorId: string,
    pageNumber: number,
    sort: SortOptions,
    ratingToFilterBy: number
  ): Promise<Reviews_Review_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Reviews_Review_ViewModel[]>(() =>
        HttpHelper.get(`api/reviews/get-reviews-data/${advisorId}/${pageNumber}/${sort}/${ratingToFilterBy}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorReviewsData()`,
        `An error occurred while loading advisor reviews data for advisor ID: ${advisorId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
