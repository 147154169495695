import React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { AppSettings } from '../../../constants';
import teamImage from './../../../img/team.jpg';
import './About.scss';

const About: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>About {AppSettings.ApplicationName}</title>
      </Helmet>
      <Row md="2" sm="1" xs="1">
        <Col className="about-image">
          <img src={teamImage} className="team-photo" alt="Nectarine Team" />
          <p>
            Founders&nbsp;
            <a href="https://www.linkedin.com/in/viviton/">Vivi Ton</a>,{' '}
            <a href="https://www.linkedin.com/in/shane-sideris-cfa-5b870840">Shane Sideris</a>, and{' '}
            <a href="https://www.linkedin.com/in/jerschneid/">Jeremy Schneider</a>
          </p>
        </Col>
        <Col className="about-section">
          <h1>About Nectarine</h1>

          <p>
            Investing can be confusing and the financial advisory industry is broken. We created Nectarine to help individual investors get
            access to top quality financial advisors without the conflicts of interest that plague the rest of the industry.
          </p>
          <p></p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
