import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ComplianceReportContent } from '../../../../ComplianceReportContent/ComplianceReportContent';
import HttpHelper from '../../../../Helpers/HttpHelper';
import './../../../../../styles/Advisor.scss';

async function populateComplianceReport(userId: string): Promise<ComplianceReport> {
  const data = await HttpHelper.get<ComplianceReport>('api/advisor/clients/' + userId + '/report');
  return data;
}

export const AdvisorAccountComplianceReport = () => {
  debugger;

  const { userID } = useParams();

  const [complianceReport, setComplianceReport] = useState<ComplianceReport | null>(null);

  React.useEffect(() => {
    const loadData = async () => {
      const result = await populateComplianceReport(userID!);
      setComplianceReport(result);
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container>{complianceReport ? <ComplianceReportContent complianceReport={complianceReport} /> : <></>}</Container>;
};
