import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavLink, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import { DashboardApiService } from '../../../../services/api/DashboardApiService';
import { LoginMenu } from '../../../api-authorization/LoginMenu';
import iconImage from './../../../../img/nectarine-icon.png';
import './Footer.scss';

const Footer: React.FC = () => {
  const [complianceDocuments, setComplianceDocuments] = useState<Advisor_ComplianceDocument_ViewModel[]>([]);

  useEffect(() => {
    const loadData = async () => {
      let result = await DashboardApiService.getBasicComplianceDocuments();

      const advDocument = result.find((v) => /adv/i.test(v.displayName));
      if (advDocument) {
        advDocument.displayName = 'Nectarine ADV 2A';
      }

      const crsDocument = result.find((v) => /crs/i.test(v.displayName));
      if (crsDocument) {
        crsDocument.displayName = 'Nectarine CRS';
      }

      // Put the terms of use and privacy policy at the top
      result = sortBy(result, (v) => {
        if (/terms|privacy/i.test(v.displayName)) {
          return `0${v.displayName}`;
        }

        return v.displayName;
      });

      setComplianceDocuments(result);
    };
    loadData();
  }, []);

  return (
    <footer>
      <Container>
        <Row>
          <Col className="center">
            <NavLink tag={Link} to={ApplicationRoutes.Root}>
              <img src={iconImage} alt="Nectarine Icon" className="icon center" />
            </NavLink>
            &copy;{new Date().getFullYear()} Nectarine, Inc.
          </Col>
          <Col>
            <Nav vertical>
              <h3>Resources</h3>
              <NavLink href="/#home">Get Started</NavLink>
              <NavLink href="/#pricing">Pricing</NavLink>
              <NavLink href="/#experts">Our Experts</NavLink>
              <LoginMenu />
            </Nav>
          </Col>
          <Col>
            <Nav vertical>
              <h3>Company</h3>
              <NavLink tag={Link} to={`/${ApplicationRoutes.Recruit}`}>
                Advisor Jobs
              </NavLink>
              <NavLink tag={Link} to={`/${ApplicationRoutes.Affiliates}`}>
                Affiliates
              </NavLink>
              <NavLink tag={Link} to={`/${ApplicationRoutes.About}`}>
                About Nectarine
              </NavLink>
              <NavLink tag={Link} to={`/${ApplicationRoutes.Contact}`}>
                Contact Us
              </NavLink>
            </Nav>
          </Col>
          <Col>
            <Nav vertical>
              <h3>Disclosures</h3>
              <NavLink tag={Link} to={`/${ApplicationRoutes.Terms}`}>
                Terms of Use
              </NavLink>

              {complianceDocuments.length ? (
                complianceDocuments.map((currDocument) => {
                  return (
                    <NavLink key={currDocument.displayName} href={currDocument.url} target="_blank" rel="noopener noreferrer">
                      {currDocument.displayName}
                    </NavLink>
                  );
                })
              ) : (
                <></>
              )}
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
