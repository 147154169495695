import React from 'react';

interface LoadingSpinnerProps {
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps) => {
  return (
    <div className="full-screen-loading">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <h1>{props.message ?? ''}</h1>
    </div>
  );
};

export default LoadingSpinner;
