//@ts-check
import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '../../../../constants';
import { Container } from 'reactstrap';

export const AdminLayout = () => {
  const navigate = useNavigate();

  const onSearch = (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(event.target);
    const searchQuery = formData.get('q');

    navigate(`/${ApplicationRoutes.AdminRoutes.Search_Full}?q=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <Container>
      <div className="admin-menu">
        <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}> Admin Home</Link> |
        <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}> Account Home</Link> |
        <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`}> Advisor Home</Link>
        <form onSubmit={onSearch}>
          <input className="admin-search" type="text" name="q" placeholder="Search name, email, ID, IP address, etc..." />
        </form>
      </div>
      <div></div>
      <Outlet />
    </Container>
  );
};
