export default class TypeformParser {
  public static formatAnswer(item: ClientIntakeResponseJsonData_FormResponseAnswer): string | number | null {
    const type = item.type;

    let response;

    try {
      switch (type) {
        case 'choice':
          response = item.choice?.label;
          break;
        case 'number':
          response = item.number;
          break;
        case 'text':
          response = item.text;
          break;
        case 'boolean':
          response = item.boolean ? 'Yes' : 'No';
          break;
        case 'choices':
          response = '';
          if (item.choices?.labels !== null) {
            response += item.choices?.labels.join(', ');
          }
          if (item.choices?.other !== null) {
            if (response !== '') {
              response += ', ';
            }
            response += item.choices?.other;
          }
          break;
        case 'email':
          response = item.email;
          break;

        default:
          response = 'Error';
          break;
      }
    } catch {
      response = 'Caught error';
    }

    return response ?? null;
  }
}
