import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

const apiRouteBase = 'api/recordings';

export default class RecordingsApiService extends ApiServiceBase {
  private static className = 'RecordingsApiService';

  public static async getPresignedS3UrlForRecording(recordingId: string): Promise<string> {
    try {
      const result = await this.makeApiCall<string>(() => HttpHelper.get(`${apiRouteBase}/get-s3-url/${recordingId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getPresignedS3UrlForRecording()`,
        `An error occurred while getting the presigned S3 URL for recording with ID: ${recordingId}`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
