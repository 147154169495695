//@ts-check
import { useState } from 'react';
import { Alert, Button, Form, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import LoadingDots from './../../../../LoadingAnimations/LoadingDots/LoadingDots';

export const AdvisorEditHourlyRate = (props) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [advisor, setAdvisor] = useState(props.advisor);
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [rate, setRate] = useState(props.advisor.hourlyRate);
  const [rateChangedAlert, setRateChangedAlert] = useState(false);
  const onDismiss = () => setRateChangedAlert(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const toggleModal = () => setShowEditModal(!showEditModal);

  function onSetRate(e) {
    hideError();

    const newRate = e.target.value;
    // Ensure the input is a valid dollar amount
    if (/^\d*\.?\d{0,2}$/.test(newRate)) {
      setRate(parseFloat(newRate));
    } else {
      showError('Please enter a valid dollar amount.');
    }
  }

  const onSave = async (event) => {
    event.preventDefault();

    setSaveLoading(true);

    var newAdvisor = { ...advisor };
    newAdvisor.hourlyRate = rate;

    AdvisorsApiService.updateAdvisorBioOrHourlyRate(newAdvisor)
      .then(() => {
        setSaveLoading(false);
        props.save(newAdvisor);
        setAdvisor(newAdvisor);
        setShowEditModal(false);
        setRateChangedAlert(true);
      })
      .catch((error) => {
        showError(error);
        setSaveLoading(false);
      });

    return false;
  };

  function showError(text) {
    let errorSection = {};

    errorSection.show = true;
    errorSection.text = text.toString();

    setErrorSection(errorSection);
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  return (
    <div>
      {advisor.hourlyRate > 0 && (
        <div>
          <span className="hourly-rate">${Math.round(advisor.hourlyRate).toLocaleString('en-US')}</span>
          <span className="results-product"> / one hour</span>
        </div>
      )}
      {!advisor.hourlyRate && <p>Not Set - Click Edit Below</p>}
      <Alert color="danger" isOpen={rateChangedAlert} toggle={onDismiss}>
        <p>
          <strong>Important! Action Required!</strong> Changes here <strong>do not automatically</strong> update the amount charged by
          Calendly. To complete this change, please visit your{' '}
          <a href="https://calendly.com/event_types/user/me" target="_blank" rel="noopener noreferrer">
            Calendly Event types page
          </a>{' '}
          then:
        </p>
        <ol>
          <li>Click on the Nectarine Advisor Meeting event</li>
          <li>Click "Booking page options" on the left</li>
          <li>Edit the "Amount to be collected" to match the number shown above</li>
          <li>Click "Save and close"</li>
        </ol>
        <p>
          Failing to do this will result in clients paying a different amount than what they agreed to in their contract and what is
          advertised on the site.
        </p>
      </Alert>
      <p>
        <Button color="primary" onClick={toggleModal}>
          Edit Hourly Rate
        </Button>
      </p>

      <Modal isOpen={showEditModal} toggle={toggleModal} className="rate-dialog">
        <Form onSubmit={onSave}>
          <ModalHeader toggle={toggleModal}>Edit Hourly Rate</ModalHeader>
          <ModalBody>
            {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
            <Label>Hourly Rate</Label>
            <InputGroup className="edit-hourly-rate" style={{ width: '150px' }}>
              <InputGroupText>$</InputGroupText>
              <Input id="hourlyRate" name="hourlyRate" type="number" value={rate} onChange={(e) => onSetRate(e)} />
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={saveLoading}>
              {saveLoading ? <LoadingDots /> : 'Save Hourly Rate'}
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
