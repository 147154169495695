class StateListing {
  constructor(
    public name: string,
    public abbreviation: string
  ) {}
}

export default class StateHelper {
  public static get usStatesAndTerritories(): StateListing[] {
    return stateListings;
  }

  public static getStateNameFromAbbreviation(abbreviation: string): string {
    return this.usStatesAndTerritories.find((state) => state.abbreviation === abbreviation)?.name ?? 'Unknown';
  }

  public static getStateNameForUrl(abbreviation: string): string | null {
    const stateName = this.getStateNameFromAbbreviation(abbreviation);

    if (!stateName) {
      return null;
    }

    return stateName.toLowerCase().replace(/\s+/g, '-').replace(/\.+/g, '');
  }
}

const stateListings = [
  new StateListing('Alabama', 'AL'),
  new StateListing('Alaska', 'AK'),
  new StateListing('Arizona', 'AZ'),
  new StateListing('Arkansas', 'AR'),
  new StateListing('California', 'CA'),
  new StateListing('Colorado', 'CO'),
  new StateListing('Connecticut', 'CT'),
  new StateListing('Delaware', 'DE'),
  new StateListing('Florida', 'FL'),
  new StateListing('Georgia', 'GA'),
  new StateListing('Hawaii', 'HI'),
  new StateListing('Idaho', 'ID'),
  new StateListing('Illinois', 'IL'),
  new StateListing('Indiana', 'IN'),
  new StateListing('Iowa', 'IA'),
  new StateListing('Kansas', 'KS'),
  new StateListing('Kentucky', 'KY'),
  new StateListing('Louisiana', 'LA'),
  new StateListing('Maine', 'ME'),
  new StateListing('Maryland', 'MD'),
  new StateListing('Massachusetts', 'MA'),
  new StateListing('Michigan', 'MI'),
  new StateListing('Minnesota', 'MN'),
  new StateListing('Mississippi', 'MS'),
  new StateListing('Missouri', 'MO'),
  new StateListing('Montana', 'MT'),
  new StateListing('Nebraska', 'NE'),
  new StateListing('Nevada', 'NV'),
  new StateListing('New Hampshire', 'NH'),
  new StateListing('New Jersey', 'NJ'),
  new StateListing('New Mexico', 'NM'),
  new StateListing('New York', 'NY'),
  new StateListing('North Carolina', 'NC'),
  new StateListing('North Dakota', 'ND'),
  new StateListing('Ohio', 'OH'),
  new StateListing('Oklahoma', 'OK'),
  new StateListing('Oregon', 'OR'),
  new StateListing('Pennsylvania', 'PA'),
  new StateListing('Rhode Island', 'RI'),
  new StateListing('South Carolina', 'SC'),
  new StateListing('South Dakota', 'SD'),
  new StateListing('Tennessee', 'TN'),
  new StateListing('Texas', 'TX'),
  new StateListing('Utah', 'UT'),
  new StateListing('Vermont', 'VT'),
  new StateListing('Virginia', 'VA'),
  new StateListing('Washington', 'WA'),
  new StateListing('West Virginia', 'WV'),
  new StateListing('Wisconsin', 'WI'),
  new StateListing('Wyoming', 'WY'),
  new StateListing('American Samoa', 'AS'),
  new StateListing('District of Columbia', 'DC'),
  new StateListing('Guam', 'GU'),
  new StateListing('Northern Mariana Islands', 'MP'),
  new StateListing('Puerto Rico', 'PR'),
  new StateListing('U.S. Virgin Islands', 'VI')
];
