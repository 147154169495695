import { useNavigate } from 'react-router-dom';

interface UseUrlHelperInterface {
  getQueryStringParameter<T>(name: string): T | undefined;
  setQueryStringParameter: (name: string, value: string | number | null) => void;
}

const useUrlHelper = (): UseUrlHelperInterface => {
  const navigate = useNavigate();

  function getQueryStringParameter<T>(name: string): T | undefined {
    const url = new URL(window.location.href);
    const value = url.searchParams.get(name) ?? undefined;
    return value ? (value as T) : undefined;
  }

  const setQueryStringParameter = (name: string, value: string | number | null): void => {
    const url = new URL(window.location.href);

    if (value) {
      url.searchParams.set(name, value.toString());
    } else {
      url.searchParams.delete(name);
    }

    navigate(`${url.pathname}${url.search}`);
  };

  return {
    getQueryStringParameter,
    setQueryStringParameter
  };
};

export default useUrlHelper;
