import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class ClientsApiService extends ApiServiceBase {
  private static className = 'ClientsApiService';

  public static async getDashboardData(): Promise<Clients_DashboardPage_ViewModel> {
    try {
      const result = await this.makeApiCall<Clients_DashboardPage_ViewModel>(() => HttpHelper.get(`api/account/dashboard`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getDashboardData()`, 'An error occurred while loading dashboard data.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
