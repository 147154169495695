import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import HttpHelper from '../Helpers/HttpHelper';
import StateHelper from '../Helpers/StateHelper';
import LoadingDots from '../LoadingAnimations/LoadingDots/LoadingDots';

async function populateUserStates(userId: string): Promise<string[]> {
  const data = await HttpHelper.get<string[]>(`/api/advisor/${userId}/states`);
  return data;
}

interface ChooseStatesProps {
  userID: string;
}

export const ChooseStates = ({ userID }: ChooseStatesProps) => {
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [saveStatesLoading, setSaveStatesLoading] = useState<boolean>(false);
  const [showSavedMessage, setShowSavedMessage] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      const result = await populateUserStates(userID);
      setSelectedStates(result);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveStates = async (event: React.FormEvent) => {
    event.preventDefault();
    // console.log('event', event);

    setSaveStatesLoading(true);

    const url = '/api/advisor/' + userID + '/states';
    HttpHelper.put(url, selectedStates)
      .then(() => {
        // console.log('End onSetStates');
        showSuccess();
        setSaveStatesLoading(false);
      })
      .catch((error) => {
        /*showError(error);*/
        console.error('Unable to add item.', error);
        setSaveStatesLoading(false);
      });

    return false;
  };

  const showSuccess = () => {
    setShowSavedMessage(true);
    setTimeout(() => setShowSavedMessage(false), 3000);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedStates((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedStates((prevSelected) => prevSelected.filter((state) => state !== value));
    }
  };

  const handleSelectAll = (e: React.MouseEvent) => {
    e.preventDefault();

    setSelectedStates(
      selectedStates.length === StateHelper.usStatesAndTerritories.length
        ? []
        : StateHelper.usStatesAndTerritories.map((state) => state.abbreviation)
    );
    return false;
  };

  const isAllSelected = selectedStates.length === StateHelper.usStatesAndTerritories.length;

  return (
    <Form onSubmit={onSaveStates} className="state-checkboxes">
      {showSavedMessage && <Alert className="saved-alert">Your states have been saved!</Alert>}

      <p>
        View and edit your state coverage below. Clients will only match with you if they reside in a state you have selected.{' '}
        <a href="#" className="select-all" onClick={handleSelectAll}>
          {isAllSelected ? 'Select none' : 'Select all'}
        </a>
      </p>
      <FormGroup>
        {StateHelper.usStatesAndTerritories.map((state) => (
          <Label check key={state.abbreviation}>
            <Input
              type="checkbox"
              value={state.abbreviation}
              checked={selectedStates.includes(state.abbreviation)}
              onChange={handleCheckboxChange}
            />
            &nbsp;{state.name}
          </Label>
        ))}
      </FormGroup>
      <Button color="primary" disabled={saveStatesLoading}>
        {saveStatesLoading ? <LoadingDots /> : 'Save States'}
      </Button>
    </Form>
  );
};
