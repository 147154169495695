import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Container, Row } from 'reactstrap';
import AdminApiService from '../../../../services/api/AdminApiService';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import { AdminEventsTable } from '../AdminEventsTable/AdminEventsTable';

export const AdminEvents = () => {
  const [events, setEvents] = useState<Admin_Event_ViewModel[] | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<Admin_Event_ViewModel[] | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getAllEvents();
      setEvents(result);
      setFilteredEvents(result);
    };

    loadData();
  }, []);

  const onDelete = async (event: Admin_Event_ViewModel) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this event? This is permanent and can't be undone.");

    if (!isConfirmed) {
      return false;
    }

    const updatedEvents = [...(events ?? [])];
    const index = updatedEvents.indexOf(event);
    if (index !== -1) {
      updatedEvents.splice(index, 1);
    }

    setEvents(updatedEvents);
    setFilteredEvents(updatedEvents);

    await AdminApiService.deleteEventById(event.id);

    return false;
  };

  const filter = (e: React.MouseEvent, type: 'paid' | 'all') => {
    if (!events) {
      return;
    }

    let filteredEvents: Admin_Event_ViewModel[] = [];

    switch (type) {
      case 'paid':
        filteredEvents = events.filter(
          (event) => event.paymentAmount > 0 && (event.status === 'Active' || event.status === 'Scheduled' || event.status === 'Completed')
        );
        break;

      default:
        filteredEvents = [...events];
        break;
    }

    setFilteredEvents(filteredEvents);
  };

  return (
    <Container>
      <Helmet>
        <title>Nectarine - Events</title>
      </Helmet>
      <Row>
        <Col>
          <h1>All Events</h1>
          <p>
            Show only:{' '}
            <Button color="light" onClick={(e) => filter(e, 'paid')}>
              Paid
            </Button>{' '}
            <Button color="light" onClick={(e) => filter(e, 'all')}>
              All
            </Button>
          </p>

          {!filteredEvents && <LoadingSpinner message="Loading..." />}
          {filteredEvents && (
            <div>
              <p>
                Showing <strong>{filteredEvents.length}</strong> results. Total Revenue:{' '}
                <strong>
                  $
                  {filteredEvents
                    .reduce(
                      (sum, event) =>
                        sum +
                        (event.paymentSuccessful && (event.status == 'Active' || event.status == 'Scheduled' || event.status == 'Completed')
                          ? event.paymentAmount
                          : 0),
                      0
                    )
                    .toLocaleString()}
                </strong>
              </p>
              <AdminEventsTable events={filteredEvents} onDelete={onDelete} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
