import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminUserAgreements: React.FC = () => {
  const [userAgreements, setUserAgreements] = useState<User_UserAgreement_ViewModel[] | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getRecentUserAgreements();
      setUserAgreements(result);
    };

    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Admin / UserAgreements</title>
      </Helmet>
      <Row md="1" sm="1" xs="1">
        <Col>
          <h1>User Agreements</h1>
          <p>
            Showing most recent <strong>100</strong> User Agrements.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>State</th>
                <th>IP&nbsp;Address</th>
                <th>Timestamp</th>
                <th>Advisor</th>
                <th>Documents</th>
              </tr>
            </thead>

            {!userAgreements ? (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <LoadingSpinner message="Loading..." />
                  </td>
                </tr>
              </tbody>
            ) : (
              <></>
            )}

            {userAgreements ? (
              <tbody>
                {userAgreements.map((currUserAgreement) => {
                  return (
                    <tr key={currUserAgreement.id}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${currUserAgreement.consumerId}/edit`}>
                          {currUserAgreement.firstName} {currUserAgreement.lastName}
                        </Link>{' '}
                        <AdminImpersonateLink userId={currUserAgreement.consumerId} />
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${currUserAgreement.consumerId}/edit`}>
                          {currUserAgreement.emailAddress}
                        </Link>
                      </td>
                      <td>{currUserAgreement.stateAbbreviation}</td>
                      <td>{currUserAgreement.ipAddress}</td>
                      <td>{new Date(currUserAgreement.agreedAtTime).toLocaleString()}</td>
                      <td>
                        {currUserAgreement.advisorId ? (
                          <div>
                            <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${currUserAgreement.advisorId}/edit`}>
                              {currUserAgreement.advisorFirstName} {currUserAgreement.advisorLastName}
                            </Link>{' '}
                            <AdminImpersonateLink userId={currUserAgreement.advisorId} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        {currUserAgreement.complianceDocuments.map((doc) => (
                          <span key={doc.id}>
                            <a href={doc.url} target="_blank" rel="noopener noreferrer">
                              {doc.displayName}
                            </a>
                            <span>, </span>
                          </span>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <></>
            )}
          </table>
        </Col>
      </Row>
    </Container>
  );
};
