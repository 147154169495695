import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  UncontrolledAccordion
} from 'reactstrap';
import AffiliatesApiService from '../../../../../services/api/AffiliatesApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import HttpHelper from '../../../../Helpers/HttpHelper';
import StateHelper from '../../../../Helpers/StateHelper';
import LoadingSpinner from '../../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

const defaultToolTipText = 'Copy to clipboard';

export const ClientAccountAffiliate: React.FC = () => {
  const [dashboard, setDashboard] = useState<Affiliates_DashboardPage_ViewModel | null>(null);
  const [shareLink, setShareLink] = useState<string | null>(null);
  const [toolTipText, setToolTipText] = useState<string>(defaultToolTipText);
  const [isEditLinkMode, setIsEditLinkMode] = useState<boolean>(false);
  const [slug, setSlug] = useState<string | null>(null);
  const [agreeToTermsButtonEnabled, setButtonEnabled] = useState<boolean>(false);

  useEffect(() => {
    loadPageData();
  }, []);

  async function loadPageData() {
    const dashboardData = await AffiliatesApiService.getDashboardData();
    setDashboard(dashboardData);
    setShareLink('https://hellonectarine.com/r/' + dashboardData.affiliate.slug);

    setSlug(dashboardData.affiliate.slug);
  }

  function cancelEditSlug(e: React.MouseEvent) {
    e.preventDefault();
    setSlug(dashboard!.affiliate.slug);
    setIsEditLinkMode(false);
  }

  function onAgreeClick() {
    setButtonEnabled(!agreeToTermsButtonEnabled);
  }

  function docSeparator(i: number, length: number) {
    if (i == 0 && length == 2) {
      return <span> and </span>;
    }
    if (i < length - 2) {
      return <span>, </span>;
    }
    if (i < length - 1) {
      return <span>, and </span>;
    }

    return <span> </span>;
  }

  function onAgreeSubmit(e: React.FormEvent) {
    e.preventDefault();

    HttpHelper.post('/api/users/agreetoaffiliateterms/')
      .then(() => {
        loadPageData();
      })
      .catch((error) => {
        alert(ErrorsHelper.getErrorMessageFromErrorObject(error.message));
        console.error('Unable to add item.', error);
      });

    return false;
  }

  const saveShareLink = async (event: React.FormEvent) => {
    event.preventDefault();

    setDashboard((v) => {
      return {
        ...v!,
        affiliate: {
          ...v!.affiliate,
          slug: slug!
        }
      };
    });

    HttpHelper.put(`/api/account/slug/${slug}`)
      .then(() => {
        setShareLink('https://hellonectarine.com/r/' + slug);
        setIsEditLinkMode(false);
      })
      .catch((error) => {
        alert(error);
        console.error(error);
        setIsEditLinkMode(false);
      });

    return false;
  };

  function copyToClipboard() {
    const textToCopy = shareLink;
    const tempInput = document.createElement('input');
    tempInput.value = textToCopy!;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setToolTipText('Link copied to clipboard!');

    setTimeout(function () {
      setToolTipText(defaultToolTipText);
    }, 2000);
  }

  return (
    <Container>
      <Helmet>
        <title>Nectarine - Share Nectarine</title>
      </Helmet>
      <Row>
        <Col>
          {!dashboard && <LoadingSpinner message="Loading..." />}
          {dashboard && dashboard.missingComplianceDocuments.length > 0 ? (
            <div>
              <h1>Agree to continue</h1>
              <p>To access your affiliate dashboard, please agree to the terms below.</p>

              <Form onSubmit={(e) => onAgreeSubmit(e)}>
                <Input id="agreeToTerms" name="agreeToTerms" type="checkbox" onChange={() => onAgreeClick()} />
                &nbsp;
                <Label for="agreeToTerms">
                  I agree to Nectarine's &nbsp;
                  {dashboard.missingComplianceDocuments.map((currDocument, i) => (
                    <span key={currDocument.displayName}>
                      <a href={currDocument.url} target="_blank" rel="noopener noreferrer">
                        {currDocument.displayName}
                      </a>
                      {docSeparator(i, dashboard.missingComplianceDocuments.length)}
                    </span>
                  ))}
                </Label>
                <Row>
                  <Button disabled={!agreeToTermsButtonEnabled} color="primary" size="lg" className="affiliate-agree-button">
                    I Agree
                  </Button>
                </Row>
              </Form>
            </div>
          ) : (
            <></>
          )}
          {dashboard && dashboard.missingComplianceDocuments.length <= 0 && (
            <div>
              <h1>My Share Link</h1>

              {dashboard && dashboard.affiliate && (
                <div>
                  {dashboard && dashboard.affiliate.slug && !isEditLinkMode && (
                    <div>
                      <p>
                        Share this link with your friends and earn <strong>10% of their purchases</strong> for the first year after they
                        sign up!
                      </p>
                      <div className="share-link" onClick={copyToClipboard}>
                        <span>{shareLink}</span>
                        <i className="fa-regular fa-copy"></i>
                        <span className="affiliate-tooltip" id="myTooltip">
                          {toolTipText}
                        </span>
                      </div>
                      &nbsp;
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsEditLinkMode(true);
                        }}
                      >
                        Edit Link
                      </a>
                      <p className="mt-4">
                        <i className="fa-duotone fa-bullseye-pointer att-icon"></i>
                        &nbsp;&nbsp;Your share link has been clicked <strong>{dashboard.affiliate.affiliateLinkClicks}</strong> times.
                      </p>
                      <p className="alert alert-info">
                        <strong>Important!</strong> When using your share link, you must disclose that you are an affiliate of Nectarine
                        using language like:
                        <br />
                        <br />
                        "I will be compensated by Nectarine if you use my affiliate link, which creates an incentive and conflict of
                        interest. I am not a current client or employee of Nectarine."
                      </p>
                    </div>
                  )}
                  {dashboard && !dashboard.affiliate.slug && !isEditLinkMode && (
                    <div>
                      <p>You haven't set your affiliate link yet. Click the button below to get started.</p>
                      <Button
                        color="primary"
                        onClick={() => {
                          setIsEditLinkMode(true);
                        }}
                      >
                        Set My Affiliate Link
                      </Button>
                    </div>
                  )}
                  {dashboard && isEditLinkMode && (
                    <Form onSubmit={saveShareLink}>
                      <p>Set your affiliate link below. This will be your unique share link. You can change this link at any time.</p>
                      {dashboard.affiliate.affiliateLinkClicks > 0 && (
                        <p className="alert alert-danger">
                          <i className="fa-regular fa-triangle-exclamation"></i>&nbsp;
                          <strong>Warning!</strong> Changing your share link will cause your old share link to no longer work. If your old
                          link is posted anywhere, it should be updated so it continues to work. Any exising referrals will still be
                          credited to you.
                        </p>
                      )}
                      <Row>
                        <Col className="set-affiliate-title">https://hellonectarine.com/r/</Col>
                        <Col className="set-affiliate-input">
                          <Input
                            type="text"
                            id="slug"
                            name="slug"
                            value={slug!}
                            required
                            minLength={4}
                            onChange={(e) => {
                              setSlug(e.target.value);
                            }}
                            placeholder="e.g. your-name-here"
                          />
                        </Col>
                      </Row>
                      <Button
                        color="light"
                        className="btn-lg"
                        onClick={(e) => {
                          cancelEditSlug(e);
                        }}
                      >
                        Cancel
                      </Button>
                      &nbsp;
                      <Button color="primary" className="btn-lg">
                        Save
                      </Button>
                    </Form>
                  )}
                </div>
              )}
              <h1>My Referrals</h1>

              {!dashboard && <LoadingSpinner message="Loading..." />}
              {dashboard && dashboard.referredUsers && dashboard.referredUsers.length > 0 && (
                <div>
                  <p>
                    Below are the users you have referred to Nectarine who signed in the last year and whose purchaes will qualify for
                    revenue share.
                  </p>
                  <UncontrolledAccordion className="accordion-primary past-meetings" toggle={() => {}}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        You have referred&nbsp;
                        <strong>{dashboard.referredUsers.length}</strong>&nbsp;active user{dashboard.referredUsers.length != 1 ? 's' : ''}.
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <table className="table advisor-events">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Signed Up</th>
                              <th>State</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboard.referredUsers.map((user, index) => (
                              <tr key={user.id}>
                                <td>{index + 1}</td>
                                <td>{DateHelper.mediumDateFormat(user.createdAt)}</td>
                                <td>{StateHelper.getStateNameFromAbbreviation(user.state)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </div>
              )}

              {dashboard && dashboard.referredUsers && dashboard.referredUsers.length === 0 && (
                <p>No users have signed up with your link yet. Keep sharing!</p>
              )}

              {dashboard && dashboard.eventsIncomplete && dashboard.eventsIncomplete.length > 0 && (
                <div>
                  <h1>Incomplete Events</h1>
                  <p>
                    Below are the events that a client you referred has booked, but they haven't completed yet. The event will be payable to
                    you once the event completes.
                  </p>
                  <UncontrolledAccordion className="accordion-primary past-meetings" toggle={() => {}}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        You have a pending &nbsp;
                        <strong>
                          $
                          {dashboard.eventsIncomplete
                            .reduce((sum, event) => sum + event.affiliateOwedAmount, 0)
                            .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </strong>
                        &nbsp;for&nbsp;<strong>{dashboard.eventsIncomplete.length}</strong>&nbsp;incomplete events
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <table className="table advisor-events">
                          <thead>
                            <tr>
                              <td></td>
                              <th>Meeting Time</th>
                              <th>Client State</th>
                              <th>Amount Pending</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboard.eventsIncomplete.map((event, index) => (
                              <tr key={event.id}>
                                <td>{index + 1}</td>
                                <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                                <td>{StateHelper.getStateNameFromAbbreviation(event.consumerState)}</td>
                                <td>
                                  $
                                  {event.affiliateOwedAmount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </div>
              )}

              {dashboard && dashboard.eventsDue && dashboard.eventsDue.length > 0 && (
                <div>
                  <h1>My Events Owed</h1>
                  <p>
                    Below are the completed events that are due to you. They will be included in an upcoming pay period based on the month
                    the event was completed.
                  </p>
                  <UncontrolledAccordion className="accordion-primary past-meetings" toggle={() => {}}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        You are owed&nbsp;
                        <strong>
                          $
                          {dashboard.eventsDue
                            .reduce((sum, event) => sum + event.affiliateOwedAmount, 0)
                            .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </strong>
                        &nbsp;for&nbsp;<strong>{dashboard.eventsDue.length}</strong>&nbsp;completed events
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <table className="table advisor-events">
                          <thead>
                            <tr>
                              <td></td>
                              <th>Meeting Time</th>
                              <th>Client State</th>
                              <th>Amount Pending</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboard.eventsDue.map((event, index) => (
                              <tr key={event.id}>
                                <td>{index + 1}</td>
                                <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                                <td>{StateHelper.getStateNameFromAbbreviation(event.consumerState)}</td>
                                <td>
                                  $
                                  {event.affiliateOwedAmount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </AccordionBody>
                    </AccordionItem>
                  </UncontrolledAccordion>
                </div>
              )}

              <h1>My Payments</h1>

              {!dashboard && <LoadingSpinner message="Loading..." />}

              {dashboard && dashboard.payments && dashboard.payments.length === 0 && <p>No payments have been made to you yet.</p>}
              {dashboard && dashboard.payments && dashboard.payments.length > 0 && (
                <div>
                  <p>
                    Below is the list of past payments made to you. All time you have made{' '}
                    <strong>${dashboard.payments.reduce((sum, payment) => sum + payment.amount, 0).toLocaleString()}</strong> from{' '}
                    <strong>{dashboard.payments.reduce((sum, payment) => sum + payment.numEvents, 0).toLocaleString()}</strong> events.
                  </p>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Payment Date</th>
                        <th>Amount</th>
                        <th>Events</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboard.payments.map((item) => (
                        <tr key={item.id}>
                          <td>{DateHelper.shortDateFormat(item.paidAt)}</td>
                          <td>${item.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                          <td>{item.numEvents}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
