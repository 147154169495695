//@ts-check
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { ApplicationRoutes } from './../../constants';
import RolesHelper from './../Helpers/RolesHelper';
import AuthorizeService from '../api-authorization/AuthorizeService';
import LoadingSpinner from '../LoadingAnimations/LoadingSpinner/LoadingSpinner';

export const RoleRestriction = ({ allowedRoles, element }) => {
  const [userRoles, setUserRoles] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  React.useEffect(() => {
    AuthorizeService.isAuthenticated().then((a) => setIsAuthenticated(a));
    async function getRoles() {
      var roles = await RolesHelper.getRoles();
      setUserRoles(roles);
    }
    getRoles();
  }, []);

  if (!allowedRoles || allowedRoles.trim() === '') {
    throw new Error('Must supply the allowedRoles attribute when using RoleRestriction');
  }

  // console.log('userRoles', userRoles);

  if (!userRoles) {
    return <LoadingSpinner />;
  }

  const hasAccess = userRoles.some((role) => allowedRoles.includes(role));

  // console.log('hasAccess', hasAccess);

  if (hasAccess) {
    return element;
  } else if (!isAuthenticated) {
    return <Navigate to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`} />;
  } else {
    return (
      <div>
        <h1>Uh oh!</h1>
        <p className="p-4">
          It looks like you're not supposed to be here. You may need to{' '}
          <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}>login</Link> first. Please
          <Link to={`/${ApplicationRoutes.Contact}`}> contact us</Link> if you think that might be a mistake!
        </p>
      </div>
    );
  }
};

export default RoleRestriction;
