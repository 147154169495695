import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Alert, Badge, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes, UserRoles } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import { ChooseStates } from '../../../Advisor/ChooseStates';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import DateHelper from '../../../Helpers/DateHelper';
import HttpHelper from '../../../Helpers/HttpHelper';
import StateHelper from '../../../Helpers/StateHelper';
import IntakeResponsesListing from '../../../IntakeResponsesListing/IntakeResponsesListing';
import LoadingSpinner from '../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';
import './../../../../styles/Admin.scss';
import { useLoadingOverlayContext } from '../../../LoadingAnimations/LoadingOverlay/LoadingOverlayContext';

export const AdminEditUser: React.FC = () => {
  const [redirectToAdminList, setRedirect] = useState<boolean>(false);
  const [user, setUser] = useState<Admin_UserDetails_ViewModel | null>(null);
  const [userToEdit, setUserToEdit] = useState<Admin_EditUser_EditModel | null>(null);
  const [roles, setRoles] = useState<Admin_UserRole_ViewModel[] | null>(null);
  const [userRoles, setUserRoles] = useState<string[] | null>(null);
  const [errorSection, setErrorSection] = useState<{ show: boolean; text: string | null }>({
    show: false,
    text: null
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [roleToAdd, setRoleToAdd] = useState<string | undefined>(undefined);

  const { userID } = useParams();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  async function loadUserData(): Promise<void> {
    const userData = await AdminApiService.getUserById(userID!);
    setUser(userData);

    setUserToEdit({
      bio: userData.bio,
      calendarLink: userData.calendarLink,
      certifications: userData.certifications,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      id: userData.id,
      isNectarine: userData.isNectarine,
      password: '',
      slug: userData.slug,
      state: userData.state,
      title: userData.title,
      userName: userData.userName
    });
  }

  async function loadRolesForUser(): Promise<void> {
    const userRoles = await AdminApiService.getRolesForUser(userID!);
    setUserRoles(userRoles);
  }

  useEffect(() => {
    const loadData = async () => {
      await loadUserData();

      await loadRolesForUser();

      const allRoles = await AdminApiService.getAllUserRoles();
      setRoles(allRoles);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.Users_Full}`} />;
  }

  function onFormChange(e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Admin_EditUser_EditModel) {
    hideError();

    setUserToEdit((v) => {
      return {
        ...v!,
        [propertyName]: e.target.value
      };
    });
  }

  const onIsNectarineValueChange = () => {
    setUserToEdit((a) => ({
      ...a!,
      isNectarine: !a!.isNectarine
    }));
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({
      show: false,
      text: null
    });
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleFileUpload = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // console.log('handleFileUpload formData', formData);

      const displayName = (event.target as any).elements['displayName'].value;
      const params = new URLSearchParams();
      params.append('displayName', displayName);

      const url = '/api/files/compliancedocument/' + userID + '?' + params.toString();

      const token = await AuthorizeService.getAccessToken();

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
        .then(async () => {
          const user = await AdminApiService.getUserById(userID!);
          setUser(user);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const onSave = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      showLoadingOverlay();

      await AdminApiService.updateUser(userToEdit!);
      setRedirect(true);
    } catch (error) {
      showError(error as string);
    } finally {
      hideLoadingOverlay();
    }

    return false;
  };

  const deleteUser = async () => {
    HttpHelper.delete('/api/users/' + userID, null)
      .then(() => {
        // console.log('DELETE response', response);
      })
      .then(() => {
        // console.log('End onDelete');
        setRedirect(true);
      })
      .catch((error) => {
        console.error(error);

        alert('Unable to delete item.');
        setRedirect(false);
      });

    return false;
  };

  const onSetDocumentActive = async (event: React.MouseEvent, docID: string, showToTheHourBuyers: boolean) => {
    event.preventDefault();

    try {
      await HttpHelper.put('/api/files/compliancedocument/setshowtothehourbuyers/' + docID, showToTheHourBuyers);
    } catch (error) {
      console.error(error);
      alert('An error occurred setting the document as active.');
    } finally {
      await loadUserData();
    }
  };

  const deleteRole = async (roleToDelete: string) => {
    try {
      showLoadingOverlay();

      await AdminApiService.deleteUserRole(userID!, roleToDelete);

      await loadRolesForUser();
    } catch (error) {
      console.error(error);
      alert(`Unable to delete role: ${roleToDelete}.`);
      setRedirect(false);
    } finally {
      hideLoadingOverlay();
    }
  };

  const addRole = async () => {
    try {
      showLoadingOverlay();

      await AdminApiService.addUserRole(userID!, roleToAdd!);

      loadRolesForUser();
    } catch (error) {
      console.log(`Unable to add role: ${roleToAdd}`);
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / Edit User</title>
      </Helmet>

      {errorSection.show && <Alert color="danger">An error happened! {errorSection.text}</Alert>}

      {!user ? <LoadingSpinner message="Loading..." /> : <></>}

      {userToEdit && userRoles ? (
        <Row>
          <Col md="6" sm="12" xs="12">
            <Form onSubmit={onSave}>
              <h2>
                Edit {userToEdit.userName} from {userToEdit.state}
              </h2>
              <Input id="id" name="id" type="hidden" value={userToEdit.id} />
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>
                      <Input
                        id="isNectarine"
                        name="isNectarine"
                        type="checkbox"
                        checked={userToEdit.isNectarine}
                        onChange={(e) => onIsNectarineValueChange()}
                      />
                      &nbsp;&nbsp;
                      <i className={'fa-duotone fa-peach' + (userToEdit.isNectarine ? ' light-blue' : '')}></i>
                      &nbsp;<strong>Is Nectarine</strong> (When checked this advisor is operating under the Nectarine RIA)
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                      id="firstName"
                      name="FirstName"
                      placeholder="Jane"
                      type="text"
                      value={userToEdit.firstName}
                      onChange={(e) => onFormChange(e, 'firstName')}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="userName">Username</Label>
                    <Input
                      id="userName"
                      name="userName"
                      placeholder="myusername"
                      value={userToEdit.userName}
                      onChange={(e) => onFormChange(e, 'userName')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      id="lastName"
                      name="LastName"
                      placeholder="Doe"
                      type="text"
                      value={userToEdit.lastName}
                      onChange={(e) => onFormChange(e, 'lastName')}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="name@example.com"
                      type="email"
                      value={userToEdit.email}
                      onChange={(e) => onFormChange(e, 'email')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input id="state" name="state" type="select" onChange={(e) => onFormChange(e, 'state')} value={userToEdit.state}>
                      <option value="">Choose a state...</option>
                      {StateHelper.usStatesAndTerritories.map((state) => (
                        <option key={state.abbreviation} value={state.abbreviation}>
                          {state.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      id="password"
                      name="password"
                      placeholder="Leave blank to leave unchanged"
                      type="text"
                      value={userToEdit.password} /* Never show the password here*/
                      onChange={(e) => onFormChange(e, 'password')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {userRoles.includes('Advisor') && (
                <div id="AdvisorStuff">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="certifications">Certifications</Label>
                        <Input
                          id="certifications"
                          name="Certifications"
                          placeholder="CFP, CFA"
                          value={userToEdit.certifications}
                          onChange={(e) => onFormChange(e, 'certifications')}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="title">Title</Label>
                        <Input
                          id="title"
                          name="Title"
                          placeholder="Financial Planner"
                          value={userToEdit.title}
                          onChange={(e) => onFormChange(e, 'title')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="calendarLink">Calendly Link</Label>
                    <Input
                      id="calendarLink"
                      name="CalendarLink"
                      placeholder="https://calendly.com/advisorname"
                      value={userToEdit.calendarLink}
                      onChange={(e) => onFormChange(e, 'calendarLink')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="calendarLink">Calendly ID</Label>
                    <Input id="calendlyID" name="CalendlyID" placeholder="" disabled={true} value={user?.calendlyID} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="bio">Advisor Bio (Exactly three lines, one bullet per line)</Label>
                    <Input
                      id="bio"
                      name="Bio"
                      type="textarea"
                      rows="3"
                      placeholder={'9 years experience\nEnjoys those dolla bills\nNever fakes the funk on a nasty dunk'}
                      value={userToEdit.bio}
                      onChange={(e) => onFormChange(e, 'bio')}
                    />
                  </FormGroup>
                </div>
              )}
              {(userRoles.includes('Advisor') || userRoles.includes('Affiliate')) && (
                <FormGroup>
                  <Label for="slug">URL slug</Label>
                  <Input
                    id="slug"
                    name="slug"
                    required
                    placeholder="e.g. shane-sideris"
                    value={userToEdit.slug}
                    onChange={(e) => onFormChange(e, 'slug')}
                  />
                </FormGroup>
              )}
              <Button color="primary" className="btn-lg">
                Save
              </Button>
            </Form>
          </Col>
          <Col md="6" sm="12" xs="12">
            <Row>
              <h2>User Roles</h2>

              {!(roles && user && userRoles) && <LoadingSpinner message="Loading..." />}
              {roles && user && userRoles && (
                <div>
                  {userRoles.map((role) => (
                    <Badge color="info" key={role} className="d-flex-inline align-items-end">
                      <span>{role}</span>

                      <i
                        className="fa-solid fa-xmark ms-3 pointer p-1"
                        onClick={() => {
                          deleteRole(role);
                        }}
                      ></i>
                    </Badge>
                  ))}

                  <div>
                    <label>Add a New Role</label>
                  </div>

                  <Input
                    id="roleName"
                    name="RoleName"
                    type="select"
                    value={roleToAdd}
                    onChange={(v) => {
                      setRoleToAdd(v.target.value);
                    }}
                  >
                    <option value="">Choose a role...</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.name}>
                        {role.name}
                      </option>
                    ))}
                  </Input>

                  <Button
                    color="primary"
                    className="mt-4"
                    onClick={() => {
                      addRole();
                    }}
                    disabled={!roleToAdd}
                  >
                    Add Role to {user.userName}
                  </Button>

                  {/* <Form onSubmit={onAddRole}>
                    <Input id="userID" name="UserID" type="hidden" value={user.id} />
                    <Input id="roleName" name="RoleName" type="select">
                      <option value="">Choose a role...</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </Input>
                    <Button color="primary" className="mt-4">
                      Add Role to {user.userName}
                    </Button>
                  </Form> */}
                </div>
              )}
            </Row>
          </Col>

          {userRoles.includes(UserRoles.Advisor) ? (
            <Row>
              <h3>Advisor States</h3>

              <ChooseStates userID={userToEdit.id} />

              <h3>Advisor Documents</h3>

              <p>
                When uploading a new document, it should automatically become active and show the ✅. It should also make inactive all other
                documents with the same name. But if you happen to need to make an old document active or inactive (for example you're
                replacing a document with a differently named document) use can use the "Set Active" buttons.
              </p>

              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Document</th>
                    <th>Original Name</th>
                    <th>Set Active</th>
                  </tr>
                </thead>
                <tbody>
                  {/* TODO - make sure this timestamp sorting works as it did before */}
                  {user && user.complianceDocuments ? (
                    <>
                      {sortBy(user.complianceDocuments, (v) => new Date(v.timestamp)).map((currDocument) => {
                        return (
                          <tr key={currDocument.id}>
                            <td>{currDocument.showToTheHourBuyers ? '✅' : '-'}</td>

                            <td>{new Date(currDocument.timestamp as any).toLocaleDateString()}</td>

                            <td>
                              <a href={currDocument.url} target="_blank" rel="noopener noreferrer">
                                {currDocument.displayName}
                              </a>
                            </td>
                            <td>{currDocument.originalFileName}</td>
                            <td>
                              {currDocument.showToTheHourBuyers ? (
                                <a href="#" onClick={(e) => onSetDocumentActive(e, currDocument.id, false)}>
                                  Set Inactive
                                </a>
                              ) : (
                                <a href="#" onClick={(e) => onSetDocumentActive(e, currDocument.id, true)}>
                                  Set Active
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>

              <h3>Upload A New Document</h3>
              <div>
                <Form onSubmit={handleFileUpload}>
                  <Input id="displayName" name="displayName" type="select">
                    <option value="">Choose a contract...</option>
                    <option key="Contract" value="Contract">
                      Contract
                    </option>
                    <option key="ADV Part 2" value="ADV Part 2">
                      ADV Part 2
                    </option>
                    <option key="Privacy Policy" value="Privacy Policy">
                      Privacy Policy
                    </option>
                    <option key="Form CRS" value="Form CRS">
                      Form CRS
                    </option>
                  </Input>
                  <Input type="file" onChange={handleFileSelect} />
                  <Button color="primary">Upload</Button>
                </Form>
              </div>
            </Row>
          ) : (
            <></>
          )}

          <h3 className="mt-5">Events as Consumer</h3>

          <table className="table">
            <thead>
              <tr>
                <th>Advisor</th>
                <th>Created At</th>
                <th>Start Time</th>
                <th>Status</th>
                <th>Links</th>
                <th>Paid</th>
              </tr>
            </thead>
            <tbody>
              {user ? (
                user.eventsAsGuest.map((event) => (
                  <tr key={event.id}>
                    {event.advisorId ? (
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.advisorId}/edit`}>{event.advisorFullName}</Link>
                        <AdminImpersonateLink userId={event.advisorId} />
                      </td>
                    ) : (
                      <></>
                    )}

                    <td>{event.advisorEmail}</td>

                    <td>{DateHelper.mediumDateFormat(event.createdAt)}</td>

                    <td>{DateHelper.mediumDateFormat(event.startTime)}</td>

                    <td>{event.status}</td>

                    <td>
                      <a href={event.location}>Zoom</a> <a href={event.rescheduleUrl}>Reschedule</a> <a href={event.cancelUrl}>Cancel</a>{' '}
                      {event.consumerId && event.advisorId ? (
                        <a href={'/leaveareview/' + event.advisorId + '/' + event.consumerId}>Review Page</a>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: event.paymentCurrency ? event.paymentCurrency : 'USD',
                        maximumFractionDigits: 0
                      }).format(event.paymentAmount)}
                      {!event.paymentSuccessful && event.paymentAmount > 0 && (
                        <span className="text-danger">
                          {' '}
                          <strong>FAILED!</strong>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>

          <h3 className="mt-5">Intake Form Responses</h3>

          <table className="table">
            <tbody>
              {user && user.intakeResponses ? (
                user.intakeResponses.map((currIntakeResponse) => {
                  const parsedJson = currIntakeResponse.jsonData;

                  return (
                    <tr key={currIntakeResponse.id}>
                      <td>
                        <strong>
                          {new Date(currIntakeResponse.submittedAt).toDateString()} - {currIntakeResponse.email}
                        </strong>
                        <br />

                        <IntakeResponsesListing
                          clientFirstName={currIntakeResponse.firstName}
                          intakeResponseData={parsedJson}
                          intakeResponseSubmitDate={currIntakeResponse.submittedAt}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>

          <h3>Other User Details</h3>
          <table className="table">
            <tbody>
              {user &&
                Object.keys(user).map((keyName) => (
                  <tr key={keyName}>
                    <td>{keyName}</td>
                    <td>{(user as any)[keyName] ? (user as any)[keyName].toString() : '-'}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <h3 className="mt-3">Danger Zone</h3>

          <div>
            <Button color="danger" onClick={() => deleteUser()}>
              Delete User
            </Button>
          </div>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  );
};
