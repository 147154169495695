import { addHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useOutletContext } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row, UncontrolledAccordion } from 'reactstrap';
import { ApplicationRoutes, RecordingStatus } from '../../../../../constants';
import ClientsApiService from '../../../../../services/api/ClientsApiService';
import RecordingsApiService from '../../../../../services/api/RecordingsApiService';
import { BeforeYourMeeting } from '../../../../BeforeYourMeeting/BeforeYourMeeting';
import DateHelper from '../../../../Helpers/DateHelper';
import IntakeResponsesListing from '../../../../IntakeResponsesListing/IntakeResponsesListing';
import './../../../../../styles/common.scss';

export const AccountIndex = () => {
  const [advisorsNeedingReview, setAdvisorsNeedingReview] = useState<Advisor_BasicDetails_ViewModel[] | null>(null);
  const [upcomingEvents, setUpcomingEvents] = useState<Advisor_ClientEvent_ViewModel[] | null>(null);
  const [pastEvents, setPastEvents] = useState<Advisor_ClientEvent_ViewModel[] | null>(null);
  const [showIntakeFormReminder, setShowIntakeFormReminder] = useState<boolean | null>(null);
  const [user] = useOutletContext<any>();
  const [recordingsAndPresignedS3Urls, setRecordingsAndPresignedS3Urls] = useState<{ [index: string]: string }>({});

  useEffect(() => {
    const loadData = async () => {
      const result = await ClientsApiService.getDashboardData();

      setAdvisorsNeedingReview(result.advisorsNeedingReview);
      setUpcomingEvents(result.upcomingEvents);
      setPastEvents(result.pastEvents);
      updateIntakeFormReminder(result.upcomingEvents);

      const distinctRecordingIds = result.pastEvents.flatMap((v) => v.recordings.map((m) => m.id));
      const presignedS3Urls = await Promise.all(distinctRecordingIds.map((v) => RecordingsApiService.getPresignedS3UrlForRecording(v)));

      setRecordingsAndPresignedS3Urls((v) => {
        const newVal = { ...v };

        distinctRecordingIds.forEach((m, i) => {
          newVal[m] = presignedS3Urls[i];
        });

        return {
          ...v,
          ...newVal
        };
      });
    };

    loadData();
  }, []);

  function updateIntakeFormReminder(upcomingEvents: Advisor_ClientEvent_ViewModel[]) {
    const reminder = upcomingEvents.some((v) => !v.intakeFormCompleted);
    setShowIntakeFormReminder(reminder);
  }

  const twoHoursFromNow = addHours(new Date(), 2);

  return (
    <Container>
      <Helmet>
        <title>Nectarine - My Account</title>
      </Helmet>
      <Row className="account-home">
        <Col>
          {user ? <h1>Hello, {user.firstName}!</h1> : <></>}

          {showIntakeFormReminder ? (
            <Row className="intake-reminder">
              <Col className="text-center">
                <h2>Don't forget to fill out your intake form!</h2>
                <p>Tell us what you need help with so your advisor can be prepared.</p>
                <a
                  href={'https://hellonectarine.typeform.com/to/QdrDHmPg#email=' + user.email + '&name=' + user.firstName}
                  className="btn btn-lg btn-primary"
                >
                  Fill Out Intake Form
                </a>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {advisorsNeedingReview && advisorsNeedingReview.length > 0 ? (
            advisorsNeedingReview.map((advisor) => (
              <Row key={advisor.id} className="review-reminder">
                <Col lg="3" className="text-center">
                  <img
                    src={advisor.profilePicUrl}
                    className="circle-image headshot"
                    alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
                  />
                </Col>
                <Col xl="7" lg="9" className="text-center">
                  <h2>How was your meeting with {advisor.firstName}?</h2>
                  <p>Please take a moment to tell us about your experience!</p>
                  <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Reviews_Full}`} className="btn btn-primary">
                    Go To Reviews Page
                  </Link>
                </Col>
                <Col xl="2" lg="0"></Col>
              </Row>
            ))
          ) : (
            <></>
          )}

          {upcomingEvents ? (
            <>
              <div>
                <h2>My Upcoming Meeting{upcomingEvents.length == 1 ? '' : 's'}</h2>
                {upcomingEvents.length == 0 && (
                  <div className="upcoming-meetings">
                    <p>You have no upcoming meetings scheduled.</p>
                    <Link to={ApplicationRoutes.Root} className="btn btn-primary btn-lg">
                      Book a Meeting
                    </Link>
                  </div>
                )}
                {upcomingEvents.length > 0 &&
                  upcomingEvents.map((event) => (
                    <React.Fragment key={event.id}>
                      <div className="upcoming-meetings">
                        <Row>
                          <Col lg="3">
                            <img
                              src={event.advisorProfilePicUrl}
                              className="circle-image headshot"
                              alt={event.advisorFirstName + ' ' + event.advisorLastName}
                            />
                          </Col>
                          <Col lg="6" className="text-start">
                            <h3>{DateHelper.longDateFormat(event.startTime)}</h3>
                            <p>
                              with {event.advisorFullName}
                              {event.advisorCertifications !== null &&
                              event.advisorCertifications !== undefined &&
                              event.advisorCertifications !== ''
                                ? ', '
                                : ''}
                              {event.advisorCertifications}
                            </p>
                            {event.intakeFormCompleted && (
                              <span>
                                <i className="fa-duotone fa-check-to-slot att-icon"></i>&nbsp;
                                <strong>Intake form received!</strong>
                              </span>
                            )}
                            {!event.intakeFormCompleted && (
                              <a
                                href={'https://hellonectarine.typeform.com/to/QdrDHmPg#email=' + user.email + '&name=' + user.firstName}
                                className="btn btn-lg btn-primary"
                              >
                                Fill Out Intake Form
                              </a>
                            )}
                          </Col>
                          <Col lg="3" className="text-start upcoming-buttons">
                            {new Date(event.startTime) < twoHoursFromNow && (
                              <a className="btn btn-primary btn-lg" href={event.location}>
                                Join Meeting
                              </a>
                            )}
                            {new Date(event.startTime) >= twoHoursFromNow && (
                              <a className="btn btn-secondary disabled btn-lg" href={event.location}>
                                Join Meeting
                              </a>
                            )}
                            <br />
                            <a className="btn btn-light" href={event.rescheduleUrl}>
                              Reschedule Meeting
                            </a>
                            <br />
                            <a className="btn btn-light" href={event.cancelUrl}>
                              Cancel Meeting
                            </a>
                            <br />
                          </Col>
                        </Row>
                      </div>

                      {event.intakeFormCompleted && event.intakeForms.length ? (
                        <div>
                          <Row>
                            <Col>
                              <div className="mt-3">
                                <UncontrolledAccordion
                                  className="accordion-primary past-meetings"
                                  defaultOpen={'0'}
                                  key={event.id}
                                  toggle={() => {}}
                                >
                                  <AccordionItem>
                                    <AccordionHeader targetId="1">View My Intake Form</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                      <IntakeResponsesListing
                                        clientFirstName={'You'}
                                        intakeResponseData={event.intakeForms[0].jsonData}
                                        intakeResponseSubmitDate={event.intakeForms[0].submittedAt}
                                      />
                                    </AccordionBody>
                                  </AccordionItem>
                                </UncontrolledAccordion>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
              </div>

              {upcomingEvents.length ? (
                <div className="my-3">
                  <BeforeYourMeeting />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {pastEvents && pastEvents.length > 0 && (
            <div>
              <h2>My Past Meetings</h2>
              {pastEvents.map((event, index) => (
                <UncontrolledAccordion
                  className="accordion-primary past-meetings"
                  defaultOpen={index == 0 ? '1' : ''}
                  key={event.id}
                  toggle={() => {}}
                >
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <strong>{DateHelper.longDateFormat(event.startTime)}</strong> &nbsp;with&nbsp;{' '}
                      <strong>{event.advisorFullName}</strong>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <Row>
                        <Col lg="3" className="text-center">
                          <img
                            src={event.advisorProfilePicUrl}
                            className="circle-image headshot"
                            alt={event.advisorFirstName + ' ' + event.advisorLastName}
                          />
                        </Col>
                        <Col xl="7" lg="9" className="d-flex align-items-center pb-4">
                          <Link className="btn btn-primary btn-lg" to={`/${ApplicationRoutes.Advisor}/${event.advisorSlug}`}>
                            Book Another Meeting With {event.advisorFirstName}
                          </Link>
                        </Col>
                        <Col xl="2" lg="0"></Col>
                      </Row>

                      <h3>Intake Responses</h3>
                      {event.intakeForms.length == 0 && (
                        <div className="intake-form">You did not submit an intake form before this meeting.</div>
                      )}

                      {event.intakeForms.map((ir, index) => {
                        const parsedJson = ir.jsonData;

                        return (
                          <IntakeResponsesListing
                            key={index}
                            clientFirstName={'You'}
                            intakeResponseData={parsedJson}
                            intakeResponseSubmitDate={ir.submittedAt}
                          />
                        );
                      })}

                      <h3>Meeting Notes</h3>
                      {!event.notesSent && (
                        <div className="meeting-notes">
                          {event.advisorFirstName} has not yet submitted notes for this meeting. They will be emailed to you at{' '}
                          <strong>{user.email}</strong> and appear here once completed.
                        </div>
                      )}
                      {event.notesSent && (
                        <div className="meeting-notes">
                          <p>
                            {event.advisorFirstName} last updated these notes{' '}
                            <strong>{DateHelper.longDateFormat(event.notesUpdated)}</strong>
                          </p>

                          <div dangerouslySetInnerHTML={{ __html: event.publicNotes }}></div>
                        </div>
                      )}

                      {event.recordings && event.recordings.length > 0 && (
                        <div>
                          <h3 className="mt-4">Meeting Recording</h3>
                          {event.recordings.map((recording, index) => (
                            <div key={recording.id}>
                              {recording && ![RecordingStatus.Processing, RecordingStatus.Error].includes(recording.status) && (
                                <div>
                                  {recordingsAndPresignedS3Urls[recording.id] ? (
                                    <>
                                      <video key={index} controls className="recording-video">
                                        <source src={recordingsAndPresignedS3Urls[recording.id]} type="video/mp4" />
                                        Your browser does not support the video tag.
                                      </video>

                                      <div className="mt-3">
                                        <a className="btn btn-light" href={recordingsAndPresignedS3Urls[recording.id]}>
                                          <i className="fa-solid fa-download"></i> Download (
                                          {(recording.fileSize / 1024.0 / 1024.0).toFixed(0)}
                                          MB)
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}
                              {recording && recording.status == 'Uploading' && (
                                <div>
                                  <i className="fa-duotone fa-upload missing-icon"></i> The recording for this meeting is{' '}
                                  <strong>{recording.durationInMinutes}</strong> minutes long and is still being processed.
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </AccordionBody>
                  </AccordionItem>
                </UncontrolledAccordion>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
