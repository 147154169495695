import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { ApplicationRoutes } from '../../../constants';
import bicycleRider from './../../../img/bicycle-rider.png';
import samplePlan1 from './../../../img/financial-plan-examples/sample-plan-1.png';
import samplePlan2 from './../../../img/financial-plan-examples/sample-plan-2.png';
import samplePlan3 from './../../../img/financial-plan-examples/sample-plan-3.png';
import samplePlan4 from './../../../img/financial-plan-examples/sample-plan-4.png';
import samplePlan5 from './../../../img/financial-plan-examples/sample-plan-5.png';
import samplePlan6 from './../../../img/financial-plan-examples/sample-plan-6.png';
import personHoldingMoney from './../../../img/person-holding-money.png';
import './ThePlan.scss';

export const ThePlan: React.FC = () => {
  const [activeCarouselIndex, setActiveCarouselIndex] = useState<number>(0);
  const [activeCarouselIndex_Mobile, setActiveCarouselIndex_Mobile] = useState<number>(0);
  const [carouselAnimating, setCarouselAnimating] = useState<boolean>(false);
  const [carouselAnimating_Mobile, setCarouselAnimating_Mobile] = useState<boolean>(false);
  const [imageBeingViewed, setImageBeingViewed] = useState<string | null>(null);

  const goToNextCarouselItem = (isMobile: boolean) => {
    if (isMobile) {
      if (carouselAnimating_Mobile) {
        return;
      }

      const nextIndex = activeCarouselIndex_Mobile === financialPlanExampleSlides_Mobile.length - 1 ? 0 : activeCarouselIndex_Mobile + 1;
      setActiveCarouselIndex_Mobile(nextIndex);
    } else {
      if (carouselAnimating) {
        return;
      }

      const nextIndex = activeCarouselIndex === financialPlanExampleSlides.length - 1 ? 0 : activeCarouselIndex + 1;
      setActiveCarouselIndex(nextIndex);
    }
  };

  const goToPreviousCarouselItem = (isMobile: boolean) => {
    if (isMobile) {
      if (carouselAnimating_Mobile) {
        return;
      }

      const nextIndex = activeCarouselIndex_Mobile === 0 ? financialPlanExampleSlides_Mobile.length - 1 : activeCarouselIndex_Mobile - 1;
      setActiveCarouselIndex_Mobile(nextIndex);
    } else {
      if (carouselAnimating) {
        return;
      }

      const nextIndex = activeCarouselIndex === 0 ? financialPlanExampleSlides.length - 1 : activeCarouselIndex - 1;
      setActiveCarouselIndex(nextIndex);
    }
  };

  const goToCarouselIndex = (isMobile: boolean, newIndex: number) => {
    if (isMobile) {
      if (carouselAnimating_Mobile) {
        return;
      }

      setActiveCarouselIndex_Mobile(newIndex);
    } else {
      if (carouselAnimating) {
        return;
      }

      setActiveCarouselIndex(newIndex);
    }
  };

  const getSectionHeaderTextWithSpacing = (title: string): ReactElement => {
    return (
      <>
        <div className="mt-5"></div>
        <div>&nbsp;</div>
        <h2 className="mt-3 semibold text-center">{title} </h2>
      </>
    );
  };

  const getGetStartedButton = (): ReactElement => {
    return (
      <Button color="primary" size="md" onClick={() => scrollToReadyToStartArea()}>
        Get started
      </Button>
    );
  };

  const getSection_Header = (): ReactElement => {
    return (
      <div className="headerRow py-3">
        <Container>
          <Row xs="1" lg="2" className="align-items-center">
            <Col className="text-center">
              <img src={personHoldingMoney} className="personHoldingMoney" />
            </Col>

            <Col>
              <h1 className="introductionText text-center">
                Say hello to
                <div className="d-lg-none"></div>
                <span className="bold"> The Plan</span>
                <span className="period"></span>
              </h1>

              <div className="tagline">
                <div className="mt-2 text-center">When one hour isn't enough.</div>
                <div className="mt-2 text-center">
                  Get a full financial plan for
                  <span className="price"> $2,500</span>.
                </div>
              </div>

              <div className="text-center mt-4">{getGetStartedButton()}</div>

              <div className="mt-5"></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const getSection_WhatIsThePlan = (): ReactElement => {
    return (
      <>
        {getSectionHeaderTextWithSpacing('What is The Plan?')}

        <Row xs="2" lg="3" className="whatIsThePlanRow mt-5">
          {whatIsThePlanSections.map((v) => {
            return (
              <Col key={v.iconClasses} className="text-center mb-5">
                <i className={`listingIcon ${v.iconClasses}`}></i>

                <div className="listingText mt-3" dangerouslySetInnerHTML={{ __html: v.text }}></div>
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  const getSection_WhatCanIAsk = (): ReactElement => {
    return (
      <>
        {getSectionHeaderTextWithSpacing('What can I ask?')}

        <div className="mt-3 text-center">
          Comparing <span className="bold">The Hour</span> vs <span className="bold">The Plan</span>, which one is right for you?
        </div>

        <Row xs="1" lg="2" className="whatCanIAskRow mt-5">
          {whatCanIAskSections.map((currSectionData, i) => {
            return (
              <Col
                key={currSectionData.title}
                className={`mb-5 d-flex justify-content-center ${i === 0 ? 'justify-content-lg-end' : 'justify-content-lg-start'}`}
              >
                <div className="listingArea">
                  <div className={`titleArea text-center ${currSectionData.color}`}>{currSectionData.title}</div>
                  <div className="contentArea">
                    <div className="bulletPointsArea">
                      <div className="mt-3" dangerouslySetInnerHTML={{ __html: currSectionData.text }}></div>

                      <ul>
                        {currSectionData.bulletPoints.map((currBulletPoint) => {
                          return <li key={currBulletPoint} dangerouslySetInnerHTML={{ __html: currBulletPoint }}></li>;
                        })}
                      </ul>
                    </div>

                    <div className="text-center">
                      <div className="priceString mt-5">
                        {currSectionData.priceString}

                        <span className="priceSuffix mt-5">{currSectionData.priceSuffix}</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 mb-4 text-center">
                    {currSectionData.buttonUrl ? (
                      <Link to={currSectionData.buttonUrl}>
                        <Button color={currSectionData.color === 'blue' ? 'primary' : 'secondary'} size="md">
                          {currSectionData.buttonText}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        color={currSectionData.color === 'blue' ? 'primary' : 'secondary'}
                        size="md"
                        onClick={currSectionData.buttonClickFn}
                      >
                        {currSectionData.buttonText}
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  const getSection_WhatDoIGet = (): ReactElement => {
    return (
      <div className="whatDoIGetRow py-3">
        <Container>
          {getSectionHeaderTextWithSpacing('What do I get with The Plan?')}

          <div className="mt-3 text-center">
            Get
            <span className="bold"> 28+ hours</span> of analysis ($4,200+ value) from a{' '}
            <span className="bold"> dedicated, expert financial advisor</span> including:
          </div>

          <Row xs="2" lg="3" className="mt-5 align-items-center">
            {whatDoIGetSections.map((currSectionData) => {
              return (
                <Col key={currSectionData.text} className="mb-5">
                  <div className="listingContainer" key={currSectionData.text}>
                    <i className={`listingIcon ${currSectionData.iconClasses}`}></i>

                    <div className="mt-3" dangerouslySetInnerHTML={{ __html: currSectionData.text }}></div>
                  </div>
                </Col>
              );
            })}
          </Row>

          <div className="text-center mt-3 mb-5">{getGetStartedButton()}</div>
        </Container>
      </div>
    );
  };

  const getSection_Timeline = (): ReactElement => {
    return (
      <>
        {getSectionHeaderTextWithSpacing('Timeline')}

        <div className="mt-3 text-center">
          Over the course of <span className="bold">eight weeks</span>, we’ll learn about you and your goals,
          <br /> craft a custom financial plan, and help you implement your plan of action.
        </div>

        <Row className="timelineRow mt-5 align-items-start">
          {timelineSection.map((currSectionData) => {
            return (
              <Col key={currSectionData.text} className="mb-5" xs="6" lg={currSectionData.largeScreenColumnSpan === 1 ? 3 : 6}>
                <div className="titleArea">{currSectionData.title}</div>

                <div className="listingContainer" key={currSectionData.text}>
                  <div className="mt-3">
                    <span dangerouslySetInnerHTML={{ __html: currSectionData.text }}></span>

                    {currSectionData.tooltip ? (
                      <>
                        <i id="questionMarkIcon" className="fa-regular fa-circle-question"></i>

                        <UncontrolledTooltip target="questionMarkIcon" innerClassName="tooltip-left-aligned tooltip-wide tooltip-white">
                          {currSectionData.tooltip.text}

                          <ul>
                            {currSectionData.tooltip.bulletPoints.map((v) => {
                              return <li key={v}>{v}</li>;
                            })}
                          </ul>
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>

        <div className="unavailableDisclaimerText">
          If you’re unavailable at any time during the eight weeks, we can pause and resume when you get back!
        </div>

        <div className="text-center mt-3 mb-5">{getGetStartedButton()}</div>
      </>
    );
  };

  const getSection_WhatDoesAFinancialPlanLookLike = (): ReactElement => {
    return (
      <>
        {getSectionHeaderTextWithSpacing('What does a financial plan look like?')}

        <div className="mt-3 text-center">
          Your 8+ page financial plan gives a full breakdown of your financial life including future projections and an
          <br /> actionable plan to meet your goals.
        </div>

        <div className="mt-5"></div>
        <div className="mt-5">&nbsp;</div>

        <div className="financialPlanExamplesArea">
          {/* 
            Only show this particular carousel on larger devices.

            NOTE: The "d-none" class will set the display to 'none', which will omit the nodes from the DOM entirely
            so nothing even gets rendered.

            NOTE: We show multiple images in each carousel item on larger screens.
           */}
          <div className="d-none d-lg-block">
            <Carousel
              activeIndex={activeCarouselIndex}
              next={() => goToNextCarouselItem(false)}
              previous={() => goToPreviousCarouselItem(false)}
            >
              <CarouselIndicators
                items={financialPlanExampleSlides}
                activeIndex={activeCarouselIndex}
                onClickHandler={(index: number) => goToCarouselIndex(false, index)}
              />

              {financialPlanExampleSlides.map((v, i) => {
                return (
                  <CarouselItem key={v.key} onExiting={() => setCarouselAnimating(true)} onExited={() => setCarouselAnimating(false)}>
                    <div className="d-flex justify-content-center gap-4">
                      {v.images.map((m) => {
                        return (
                          <img
                            key={m.src}
                            className="carouselImage"
                            src={m.src}
                            alt={m.altText}
                            onClick={() => {
                              setImageBeingViewed(m.src);
                            }}
                          />
                        );
                      })}
                    </div>
                  </CarouselItem>
                );
              })}

              <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => goToPreviousCarouselItem(false)} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={() => goToNextCarouselItem(false)} />
            </Carousel>
          </div>

          {/* 
            Only show this particular carousel on larger devices.

            NOTE: The "d-lg-none" class will set the display to 'none', which will omit the nodes from the DOM entirely
            so nothing even gets rendered.

            NOTE: We show a single image on each carousel item on small screens.
           */}
          <div className="d-lg-none">
            <Carousel
              activeIndex={activeCarouselIndex_Mobile}
              next={() => goToNextCarouselItem(true)}
              previous={() => goToPreviousCarouselItem(true)}
            >
              <CarouselIndicators
                items={financialPlanExampleSlides_Mobile}
                activeIndex={activeCarouselIndex_Mobile}
                onClickHandler={(index: number) => goToCarouselIndex(true, index)}
              />

              {financialPlanExampleSlides_Mobile.map((v, i) => {
                return (
                  <CarouselItem
                    key={v.src}
                    onExiting={() => setCarouselAnimating_Mobile(true)}
                    onExited={() => setCarouselAnimating_Mobile(false)}
                  >
                    <div className="text-center">
                      <img
                        className="carouselImage"
                        src={v.src}
                        alt={v.altText}
                        onClick={() => {
                          setImageBeingViewed(v.src);
                        }}
                      />
                    </div>
                  </CarouselItem>
                );
              })}

              <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => goToPreviousCarouselItem(true)} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={() => goToNextCarouselItem(true)} />
            </Carousel>
          </div>

          <Modal
            isOpen={imageBeingViewed !== null}
            toggle={() => {
              setImageBeingViewed(null);
            }}
            className="full-screen-modal"
          >
            <ModalBody>
              <img
                src={imageBeingViewed!}
                onClick={() => {
                  setImageBeingViewed(null);
                }}
              />
            </ModalBody>
          </Modal>

          <div className="mt-5"></div>

          <div className="text-center mt-4">{getGetStartedButton()}</div>

          <div className="mt-5">&nbsp;</div>
        </div>
      </>
    );
  };

  const getSection_ReadyToStart = (): ReactElement => {
    return (
      <div id={readyToStartAreaId} className="readyToStartArea pt-4">
        {getSectionHeaderTextWithSpacing('Ready to start with The Plan?')}

        <Container>
          <>
            <Row xs="1" lg="2" className="align-items-center">
              <Col className="text-center">
                <img src={bicycleRider} className="bicycleRider" />
              </Col>

              <Col>
                <div className="d-inline-block">
                  <div className="priceSection">
                    <h1>
                      <span className="priceText">$2,500</span>

                      <span className="priceSuffix"> / eight weeks </span>
                    </h1>
                  </div>

                  <ul>
                    <li>8+ page comprehensive financial plan</li>
                    <li>28+ hours with a dedicated financial advisor of your choice</li>
                    <li>Choose one of the next steps below to get started!</li>
                  </ul>

                  <div className="paymentDisclaimer">The $2,500 payment is due after the first meeting.</div>
                </div>
              </Col>
            </Row>

            <div className="mt-5"></div>

            <div className="listingArea">
              <Row xs="1" lg="3">
                {readyToStartListings.map((v) => {
                  return (
                    <Col key={v.iconClasses} className="text-center d-flex justify-content-center mb-5">
                      <div className="listingContainer h-100">
                        <i className={`listingIcon ${v.iconClasses}`}></i>

                        <div className="bold mt-4">{v.title}</div>

                        <div className="listingText mt-3" dangerouslySetInnerHTML={{ __html: v.text }}></div>

                        <div className="mt-4"></div>

                        <Link to={v.buttonUrl} target={v.shouldOpenInNewTab ? '_blank' : undefined}>
                          <Button color="primary" size="md">
                            {v.buttonText}
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <div className="mt-5"></div>
            </div>
          </>
        </Container>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Nectarine - The Plan</title>
      </Helmet>

      {/* NOTE: This section uses a full-width blue background, so it handles its own <Container> */}
      {getSection_Header()}

      <Container>
        {getSection_WhatIsThePlan()}

        {getSection_WhatCanIAsk()}
      </Container>

      {/* NOTE: This section uses a full-width blue background, so it handles its own <Container> */}
      {getSection_WhatDoIGet()}

      <Container>
        {getSection_Timeline()}

        <div>&nbsp;</div>

        {getSection_WhatDoesAFinancialPlanLookLike()}
      </Container>

      {/* NOTE: This section uses a full-width blue background, so it handles its own <Container> */}
      {getSection_ReadyToStart()}
    </>
  );
};

const wrapTextInBoldSpan = (text: string): string => {
  return `<span class="semibold">${text}</span>`;
};

const scrollToReadyToStartArea = (): void => {
  document.getElementById(readyToStartAreaId)?.scrollIntoView();
};

const readyToStartAreaId = 'ready-to-start';

type IconAndText = { iconClasses: string; text: string; title?: string };

const whatIsThePlanSections: IconAndText[] = [
  {
    iconClasses: 'fa-duotone fa-solid fa-people-arrows',
    text: `Collaborate with a ${wrapTextInBoldSpan('dedicated <br/> advisor')} for 8 weeks`
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-route',
    text: `Get projections based on <br/>${wrapTextInBoldSpan('different life scenarios')} like <br/>having kids or buying a home`
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-island-tropical',
    text: `Get a roadmap of when you can <br/>${wrapTextInBoldSpan('retire')}`
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-clipboard-list-check',
    text: `Get ${wrapTextInBoldSpan('specific recommendations')}<br/> on everything in your financial<br/> life`
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-chart-simple',
    text: `Dig deep into topics like<br/> ${wrapTextInBoldSpan('insurance, employer stock,<br/>rental properties')}`
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-comments-question-check',
    text: `Get help with ${wrapTextInBoldSpan('accountability</span> & <span class="semibold">implementation')}`
  }
];

const whatCanIAskSections: {
  color: 'blue' | 'gray';
  title: string;
  text: string;
  bulletPoints: string[];
  priceString: string;
  priceSuffix: string;
  buttonText: string;
  buttonUrl?: string;
  buttonClickFn?: () => void;
}[] = [
  {
    title: 'The Hour',
    color: 'gray',
    priceString: '$150-$250',
    priceSuffix: '/ hour',
    text: `If you have ${wrapTextInBoldSpan('1-3 questions')} like:`,
    buttonText: 'Find an advisor',
    buttonUrl: `/${ApplicationRoutes.Advisors}`,
    bulletPoints: [
      'Can you review my investment portfolio?',
      'What should I invest in?',
      'How much house can I afford?',
      'How do I roll over my old 401k?',
      'Should I go with Roth or traditional?',
      'How do I invest for my kids?',
      'Can I get a second opinion on my financial advisor?'
    ]
  },

  {
    title: 'The Plan',
    color: 'blue',
    priceString: '$2,500',
    priceSuffix: '/ 8 weeks',
    text: `Get a ${wrapTextInBoldSpan('thorough analysis')} on your financial life on topics such as:`,
    buttonText: 'Get started',
    buttonClickFn: scrollToReadyToStartArea,
    bulletPoints: [
      'When can I retire?',
      'How would having children or taking time off work affect my financial future? ',
      'When should I exercise my employer stock options?',
      'Should I do Roth conversions?',
      'Should I sell my rental property?<br/>' + `+ everything in ${wrapTextInBoldSpan('The Hour')}`
    ]
  }
];

const whatDoIGetSections: IconAndText[] = [
  {
    iconClasses: 'fa-regular fa-book-open-cover',
    text: `${wrapTextInBoldSpan('8+ page')} custom <br/>financial plan`
  },
  {
    iconClasses: 'fa-regular fa-gears',
    text: `${wrapTextInBoldSpan('8 hours')} of expert <br/>planning & analysis`
  },
  {
    iconClasses: 'fa-regular fa-magnifying-glass-dollar',
    text: `${wrapTextInBoldSpan('5 hours')} of document <br/>review`
  },
  {
    iconClasses: 'fa-regular fa-video',
    text: `${wrapTextInBoldSpan('3 one-hour')} meetings`
  },
  {
    iconClasses: 'fa-regular fa-handshake-angle',
    text: `${wrapTextInBoldSpan('4+ hours')} of <br/>implementation help`
  },
  {
    iconClasses: 'fa-regular fa-paper-plane',
    text: `${wrapTextInBoldSpan('Unlimited email')} <br/>support for 8 weeks`
  }
];

const timelineSection: {
  title: string;
  text: string;
  largeScreenColumnSpan: number;
  tooltip?: {
    text: string;
    bulletPoints: string[];
  };
}[] = [
  {
    title: 'Week 1',
    text: 'Link your accounts<br/> and upload your <br/>documents',
    largeScreenColumnSpan: 1,
    tooltip: {
      text: `You'll get access to a secure portal to provide the following:`,
      bulletPoints: [
        'Income & expenses',
        'Investment statements',
        'Debt statements',
        'Personal tax returns',
        'Insurance statements',
        'Real estate addresses',
        'Employee benefits packages',
        'Estate documents'
      ]
    }
  },
  {
    title: 'Week 2',
    text: `${wrapTextInBoldSpan('Meeting 1:')} Tell us <br/>about you, your <br/>family, and your goals`,
    largeScreenColumnSpan: 1
  },
  {
    title: 'Weeks 3 & 4',
    text: 'Your advisor analyzes your <br/>financial situation and creates a <br/>comprehensive plan',
    largeScreenColumnSpan: 2
  },
  {
    title: 'Week 5',
    text: `${wrapTextInBoldSpan('Meeting 2:')}<br/> Presentation of plan <br/>& actionable steps`,
    largeScreenColumnSpan: 1
  },
  {
    title: 'Weeks 6 & 7',
    text: 'You will implement your <br/>recommendations & use your <br/>advisor as a resource',
    largeScreenColumnSpan: 2
  },
  {
    title: 'Week 8',
    text: `${wrapTextInBoldSpan('Meeting 3:<br/>')} Accountability and <br/>wrap up`,
    largeScreenColumnSpan: 1
  }
];

type FinancialPlanExampleImage = { src: string; altText: string; caption: string; key: number };

const financialPlanExampleSlides_Mobile: FinancialPlanExampleImage[] = [
  {
    src: samplePlan1,
    altText: 'Financial Plan Example 1',
    caption: '',
    key: 1
  },
  {
    src: samplePlan2,
    altText: 'Financial Plan Example 2',
    caption: '',
    key: 2
  },
  {
    src: samplePlan3,
    altText: 'Financial Plan Example 3',
    caption: '',
    key: 3
  },
  {
    src: samplePlan4,
    altText: 'Financial Plan Example 4',
    caption: '',
    key: 4
  },
  {
    src: samplePlan5,
    altText: 'Financial Plan Example 5',
    caption: '',
    key: 5
  },
  {
    src: samplePlan6,
    altText: 'Financial Plan Example 6',
    caption: '',
    key: 6
  }
];

const financialPlanExampleSlides: GroupedFinancialPlanExampleImage[] = [
  { key: 1, images: financialPlanExampleSlides_Mobile.slice(0, 3) },
  { key: 2, images: financialPlanExampleSlides_Mobile.slice(3, 6) }
];

type GroupedFinancialPlanExampleImage = {
  key: number;
  images: FinancialPlanExampleImage[];
};

const readyToStartListings: (IconAndText & { buttonUrl: string; buttonText: string; shouldOpenInNewTab: boolean })[] = [
  {
    iconClasses: 'fa-duotone fa-solid fa-user-magnifying-glass',
    title: 'Choose an advisor',
    text: `Make your pick! If you book<br/> ${wrapTextInBoldSpan('The Hour')} with an advisor you<br/> can apply the cost toward <br/>${wrapTextInBoldSpan('The Plan')}!`,
    buttonText: 'See all advisors',
    buttonUrl: `/${ApplicationRoutes.Advisors}`,
    shouldOpenInNewTab: false
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-messages-question',
    title: 'Book a chat & ask questions',
    text: `Can't decide? Have <br/> questions? ${wrapTextInBoldSpan('Book a 20 minute  <br/> chat')} with a team member and <br/>we can help!`,
    buttonText: 'Book a free chat',
    buttonUrl: 'https://calendly.com/nectarine-shane-sideris/nectarine-financial-plan-chat',
    shouldOpenInNewTab: true
  },
  {
    iconClasses: 'fa-duotone fa-solid fa-user-check',
    title: 'Made your pick?',
    text: `When you're ${wrapTextInBoldSpan('ready to start')},<br/>  fill out a form and we'll reach <br/> out with the next steps!`,
    buttonText: 'Get started',
    buttonUrl: 'https://hellonectarine.typeform.com/theplansignup',
    shouldOpenInNewTab: true
  }
];
