import AuthorizeService from '../api-authorization/AuthorizeService';

export default class RolesHelper {
  public static async getRoles(): Promise<string[]> {
    const user = await AuthorizeService.getUser();

    if (!user) {
      return [];
    }

    let roles = user.role;

    if (!Array.isArray(user.role)) {
      roles = [user.role];
    }

    return roles;
  }
}
