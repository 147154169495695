import { CommonApiBadRequestErrors, CommonErrorMessages } from '../../constants';

export default class ErrorsHelper {
  public static getErrorMessageFromErrorObject(errorObject: unknown): string {
    if (typeof errorObject === 'string') {
      const errorObjectAsString: string = (errorObject || '').trim();

      if (!errorObjectAsString) {
        return CommonErrorMessages.General;
      }

      switch (errorObjectAsString) {
        case 'Failed to fetch':
          return CommonErrorMessages.General;

        case CommonApiBadRequestErrors.NotLoggedIn:
          return CommonErrorMessages.NotLoggedIn;

        default:
          return errorObjectAsString;
      }
    }

    return CommonErrorMessages.General;
  }
}
