import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppSettings } from '../../../constants';
import UsersApiService from '../../../services/api/UsersApiService';
import { useLoadingOverlayContext } from '../../LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import heroImage from './../../../img/investing-experts.png';

const Contact: React.FC = () => {
  const [messageSentSection, setMessageSentSection] = useState<{ show: boolean; text: string | null; isError: boolean }>({
    show: false,
    text: null,
    isError: false
  });

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  function showMessage(isError: boolean, text: string) {
    setMessageSentSection({
      show: true,
      text,
      isError
    });
  }

  const onSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // extract form data
    const formdata = new FormData(event.target as HTMLFormElement);

    // convert FormData to json object
    // SOURCE: https://stackoverflow.com/a/46774073
    const formItem: any = {};
    formdata.forEach(function (value, prop) {
      formItem[prop] = value;
    });

    try {
      showLoadingOverlay();

      await UsersApiService.contactUs(formItem.name, formItem.email, formItem.message);

      showMessage(false, "Your message was sent! We'll get back to you shortly.");
    } catch (error) {
      showMessage(true, 'There was a problem sending your message. Please email us at team@hellonectarine.com');
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Contact Us</title>
      </Helmet>

      <Container>
        <Form className="contact-us" onSubmit={onSave}>
          <Row>
            {messageSentSection.show ? (
              <Col md={6}>
                {!messageSentSection.isError ? (
                  <>
                    <h1>Thanks for saying hi!</h1>
                    <p>{messageSentSection.text}</p>
                  </>
                ) : (
                  <>
                    <h1>Something went wrong</h1>
                    <p className="c-red">{messageSentSection.text}</p>
                  </>
                )}
              </Col>
            ) : (
              <></>
            )}

            {!messageSentSection.show ? (
              <Col md={6}>
                <h1>Say Hello to {AppSettings.ApplicationName}</h1>

                <p>Send us a message below and we'll get back to you ASAP!</p>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input id="name" name="name" placeholder="Your Name" type="text" required />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input id="email" name="email" type="email" placeholder="your.name@example.com" required />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="message">Message</Label>
                      <Input id="message" type="textarea" name="message" placeholder="Your message here..." required />
                    </FormGroup>

                    <Button className="btn btn-primary">Contact Us!</Button>
                  </Col>
                </Row>
              </Col>
            ) : (
              <></>
            )}

            <Col md={6}>
              <img src={heroImage} className="hero center" alt="Nectarine Investing Experts Illustration" />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Contact;
