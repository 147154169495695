//@ts-check
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import { ChooseStates } from '../../../../Advisor/ChooseStates';
import AuthorizeService from '../../../../api-authorization/AuthorizeService';
import LoadingDots from '../../../../LoadingAnimations/LoadingDots/LoadingDots';
import LoadingSpinner from '../../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

import { AdvisorEditHourlyRate } from '../AdvisorEditHourlyRate/AdvisorEditHourlyRate';

export const AdvisorAccountProfile = () => {
  const [user, setUser] = useState<User_BasicDetails_ViewModel | null>(null);
  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [advisorBeingEdited, setAdvisorBeingEdited] = useState<Advisor_EditAdvisor_EditModel | null>();
  const [redirectToCalendar] = React.useState(false);
  const [saveShowReviewsLoading, setSaveShowReviewsLoading] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [errorSection, setErrorSection] = useState<{ show: boolean; text: string | null }>({ show: false, text: null });
  const [shortBioLines, setShortBioLines] = useState<string[] | null>(null);
  const [saveResultsBulletsLoading, setSaveResultsBulletsLoading] = useState(false);

  const numShortBioLines = 3;

  useEffect(() => {
    const loadData = async () => {
      const userViewModel = await AuthorizeService.populateActiveUser();
      setUser(userViewModel);

      const advisor = await AdvisorsApiService.getAdvisorDetailsBySlug(userViewModel.slug, false, false, false);
      setAdvisor(advisor);

      setAdvisorBeingEdited({
        ...advisor!
      });

      const shortBio = userViewModel.bio || '\n\n';
      let array = shortBio.split('\n');
      // Ensure array has exactly three elements
      if (array.length < numShortBioLines) {
        // If array has less than three elements, pad with empty strings until it has three elements
        while (array.length < numShortBioLines) {
          array.push('');
        }
      } else if (array.length > numShortBioLines) {
        // If array has more than three elements, truncate it to have only the first three elements
        array = array.slice(0, numShortBioLines);
      }

      setShortBioLines(array);
    };

    loadData();
  }, []);

  const onShortBioChanged = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedBioLines = [...shortBioLines!];
    updatedBioLines[index] = event.target.value;
    setShortBioLines(updatedBioLines);
  };

  const onSaveShortBio = async (event: React.FormEvent) => {
    event.preventDefault();
    hideError();

    const newBio = shortBioLines!.join('\n');

    setAdvisorBeingEdited((v) => {
      return {
        ...v!,
        bio: newBio
      };
    });

    setSaveResultsBulletsLoading(true);

    try {
      await AdvisorsApiService.updateAdvisor({
        ...advisorBeingEdited!,
        bio: newBio
      });

      setShowSavedMessage(true);
      setTimeout(() => setShowSavedMessage(false), 3000);
      setSaveResultsBulletsLoading(false);
    } catch (error) {
      showError(error as string);
      setSaveResultsBulletsLoading(false);
      console.error('Unable to add item.', error);
    }

    return false;
  };

  const onSaveShowReviews = async (event: React.FormEvent) => {
    event.preventDefault();
    hideError();

    // console.log('onSave json', user);

    setSaveShowReviewsLoading(true);

    try {
      await AdvisorsApiService.updateAdvisor({
        ...advisor!
      });

      setShowSavedMessage(true);
      setTimeout(() => setShowSavedMessage(false), 3000);
      setSaveShowReviewsLoading(false);
    } catch (error) {
      showError(error as string);
      setSaveShowReviewsLoading(false);
      console.error('Unable to add item.', error);
    }

    return false;
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  if (redirectToCalendar) {
    window.location.href = advisor!.calendarLink;
    return <LoadingSpinner message="Looking up calendar..." />;
  }

  return (
    <Container>
      <Helmet>
        <title>Nectarine - Advisor Profile</title>
      </Helmet>
      {showSavedMessage && <Alert className="saved-alert">Your preference has been saved!</Alert>}
      <Row>
        <Col className="advisor-account">
          <h1>My Profile</h1>

          {!advisor && <LoadingSpinner message="Loading..." />}
          {advisor && (
            <div>
              <p className="py-4">
                View and edit your public <strong>profile page</strong> below and <strong>profile pic</strong>. This is what clients will
                see when they search for you and book a call.
              </p>
              <p>
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`}>
                  <img src={advisor.profilePicUrl} alt="Profile" className="circle-image headshot" style={{ maxWidth: '200px' }} />
                </Link>
              </p>
              <p>
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`} className="btn btn-primary">
                  View & edit public profile page for {advisor.firstName} {advisor.lastName}
                </Link>
              </p>
              <h2>My Rate</h2>
              <AdvisorEditHourlyRate advisor={advisor} save={setAdvisor} />
              <h2>My Results Bullets</h2>
              <p>Edit the three bullets that will be shown below your photo on the search results page.</p>
              <Form onSubmit={onSaveShortBio}>
                <FormGroup>
                  <Input
                    type="text"
                    className="short-bio"
                    id="shortBio1"
                    value={shortBioLines![0]}
                    onChange={(event) => onShortBioChanged(0, event)}
                    maxLength={60}
                  />
                  <Input
                    type="text"
                    className="short-bio"
                    id="shortBio2"
                    value={shortBioLines![1]}
                    onChange={(event) => onShortBioChanged(1, event)}
                    maxLength={60}
                  />
                  <Input
                    type="text"
                    className="short-bio"
                    id="shortBio3"
                    value={shortBioLines![2]}
                    onChange={(event) => onShortBioChanged(2, event)}
                    maxLength={60}
                  />
                </FormGroup>
                <Button color="primary" disabled={saveResultsBulletsLoading}>
                  {saveResultsBulletsLoading ? <LoadingDots /> : 'Save Results Bullets'}
                </Button>
              </Form>

              <h2>My States</h2>
              <ChooseStates userID={advisor.id} />
              <h2>Show Reviews</h2>
              <p>
                Choose whether the reviews you receive from clients will be shown publicly on the search results and on your profile page.
                We recommend leaving the box checked unless local regulations require you to hide reviews.
              </p>
              {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

              <Form onSubmit={onSaveShowReviews}>
                <p>
                  <Label>
                    <Input
                      id="showReviews"
                      type="checkbox"
                      checked={advisor.showReviews}
                      onChange={(e) => {
                        setAdvisorBeingEdited((v) => ({
                          ...v!,
                          showReviews: e.target.checked
                        }));
                      }}
                    />
                    &nbsp;Show my reviews publicly
                  </Label>
                </p>
                <p>
                  <Button color="primary" disabled={saveShowReviewsLoading}>
                    {saveShowReviewsLoading ? <LoadingDots /> : 'Save Review Status'}
                  </Button>
                </p>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
