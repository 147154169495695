//@ts-check
import { useState } from 'react';
import { Alert, Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AuthorizeService from '../../../../api-authorization/AuthorizeService';
import LoadingDots from './../../../../LoadingAnimations/LoadingDots/LoadingDots';

export const AdvisorEditProfilePhoto = (props) => {
  const [savePhotoLoading, setSavePhotoLoading] = useState(false);
  // const [advisor] = useState(props.advisor);
  const [errorSection, setErrorSection] = useState({ show: false, text: null });
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    hideError();
  };

  //Post the selected file to the API at /api/advisor/photo
  const onSave = async (event) => {
    event.preventDefault();

    if (selectedFile) {
      hideError();
      setSavePhotoLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);

      const token = await AuthorizeService.getAccessToken();

      fetch('/api/advisor/profilepic', {
        method: 'POST',
        body: formData,
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`
            }
      })
        .then((photo) => {
          //Update advisor with returned photo object
          props.advisor.photos = [photo];
          props.save(props.advisor);
          setSavePhotoLoading(false);
        })
        .catch((error) => {
          showError(error);
          setSavePhotoLoading(false);
        });
    }
  };

  function showError(text) {
    let errorSection = {};

    errorSection.show = true;
    errorSection.text = text.toString();

    console.error('showError', text);

    setErrorSection(errorSection);
  }

  function hideError() {
    setErrorSection({ show: false });
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <Form onSubmit={onSave}>
        <ModalHeader toggle={props.toggle}>Upload New Profile Pic</ModalHeader>
        <ModalBody>
          {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
          <p>Please make sure you are looking directly at the camera, and your face and part of your shoulders are visible.</p>

          <Input type="file" onChange={handleFileSelect} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={savePhotoLoading}>
            {savePhotoLoading ? <LoadingDots /> : 'Save Profile Pic'}
          </Button>{' '}
        </ModalFooter>
      </Form>
    </Modal>
  );
};
