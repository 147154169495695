//@ts-check
﻿import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes } from './../../../../constants';
import LoadingSpinner from './../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import './../../../../styles/Admin.scss';

async function getRole(roleID) {
  await new Promise((r) => setTimeout(r, 200));

  const response = await fetch('/api/admin/roles/' + roleID);
  const data = await response.json();
  // console.log('Role', data);
  return data;
}

export const AdminEditRole = () => {
  const [redirectToAdminList, setRedirect] = React.useState(false);
  const [role, setRole] = React.useState(null);

  let { roleID } = useParams();

  // console.log('roleID', roleID);

  useEffect(() => {
    const loadData = async () => {
      const result = await getRole(roleID);
      setRole(result);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // effect runs when user state is updated
  React.useEffect(() => {
    // reset form with user data
    //reset(role);
    // role && console.log('useEffect Role', role);
  }, [role]);

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.RolesRoutes.Root_Full}`} />;
  }

  function onFormChange(e) {
    // console.log('onFormChange', e);
    setRole((a) => ({
      ...a,
      [e.target.id]: e.target.value
    }));
  }

  const onSave = async (event) => {
    event.preventDefault();

    // extract form data
    const formdata = new FormData(event.target);

    // console.log('onSubmit formData', formdata);

    // convert FormData to json object
    // SOURCE: https://stackoverflow.com/a/46774073
    const formItem = {};
    formdata.forEach(function (value, prop) {
      formItem[prop] = value;
    });

    // console.log('json', formItem);

    fetch('/api/admin/roles/' + formItem.RoleID, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formItem)
    })
      .then(() => {
        // console.log('response', response);
        //response.json()
      })
      .then(() => {
        console.log('End addItem');
        setRedirect(true);
      })
      .catch((error) => console.log('Unable to add item.', error));

    return false;
  };

  const onDelete = async (event) => {
    event.preventDefault();

    const formdata = new FormData(event.target);
    const formItem = {};
    formdata.forEach(function (value, prop) {
      formItem[prop] = value;
    });

    fetch('/api/admin/roles/' + formItem.id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        // console.log('DELETE response', response);
      })
      .then(() => {
        // console.log('End onDelete');
        setRedirect(true);
      })
      .catch((error) => {
        alert('Unable to delete item.', error);
        setRedirect(false);
      });

    return false;
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / Edit Role</title>
      </Helmet>
      <Row md="2" sm="1" xs="1">
        <Col>
          {!role && <LoadingSpinner message="Loading..." />}
          {role && (
            <div>
              <Form onSubmit={onSave}>
                <h2>Edit {role.name}</h2>
                <Input id="id" name="ID" type="hidden" value={role.id} />
                <FormGroup>
                  <Label for="role">Role Name</Label>
                  <Input id="name" name="Name" required value={role.name} onChange={(e) => onFormChange(e)} />
                </FormGroup>
                <Button color="primary">Save</Button>
              </Form>
              <h3>Danger Zone</h3>
              <Form onSubmit={onDelete}>
                <Input id="id" name="id" type="hidden" value={role.id} />
                <Button color="danger">Delete</Button>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
