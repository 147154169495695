import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useOutletContext } from 'react-router-dom';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import ReviewsApiService from '../../../../../services/api/ReviewsApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import LoadingSpinner from '../../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import { ReviewForm } from '../../../../ReviewForm/ReviewForm';

function getUserName(review: Reviews_Review_ViewModel) {
  return `${review.revieweeFirstName} ${review.revieweeLastName}${review.revieweeCertifications ? ', ' + review.revieweeCertifications : ''}`;
}

export const ClientAccountReviews: React.FC = () => {
  const [advisorsToReview, setAdvisorsToReview] = useState<User_BasicDetails_ViewModel[] | null>(null);
  const [advisorBeingReviewed, setAdvisorBeingReviewed] = useState<User_BasicDetails_ViewModel | null>(null);

  //The review this user is editing
  const [review, setReview] = useState<Reviews_Review_EditModel | null>({
    body: '',
    competenceRating: 0,
    coverageRating: 0,
    displayName: '',
    privateComments: '',
    rating: 0,
    recapRating: 0,
    recommendationRating: 0,
    revieweeId: '',
    reviewerId: '',
    title: ''
  });

  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [reviews, setReviews] = useState<Reviews_Review_ViewModel[] | null>(null); //The reviews this user has posted
  const [user] = useOutletContext<any>();

  React.useEffect(() => {
    loadPageData();
  }, []);

  async function loadPageData(): Promise<void> {
    const reviewsData = await ReviewsApiService.getReviewsForCurrentUser();

    setReviews(reviewsData);

    const advisorsToReviewData = await AdvisorsApiService.getAdvisorsToReview();
    setAdvisorsToReview(advisorsToReviewData);
  }

  function onEditReview(e: React.MouseEvent, review: Reviews_Review_ViewModel) {
    e.preventDefault();

    const advisor: Partial<User_BasicDetails_ViewModel> = {
      firstName: review.revieweeFirstName,
      lastName: review.revieweeLastName,
      certifications: review.revieweeCertifications,
      id: review.revieweeId,
      fullName: review.revieweeFullName,
      profilePicUrl: review.revieweeProfilePicUrl
    };

    setReview(review);
    setAdvisorBeingReviewed(advisor as User_BasicDetails_ViewModel);

    return false;
  }

  // const showError = (text) => {
  //   let errorSection = {};

  //   errorSection.show = true;
  //   errorSection.text = text.toString();

  //   setErrorSection(errorSection);
  // };

  // function onUserChange(e) {
  //   setUser((a) => ({
  //     ...a,
  //     [e.target.name]: e.target.value,
  //   }));
  //   console.log('onFormChange', e.target.name, e.target.value);
  //   console.log('User', user);
  // }

  const showSuccess = async () => {
    // setErrorSection({ show: false, text: null });
    setAdvisorBeingReviewed(null);
    setAdvisorsToReview(null);
    setReviews(null);
    setReview(null);

    await loadPageData();

    setShowSavedMessage(true);
    setTimeout(() => setShowSavedMessage(false), 3000);
  };

  return (
    <Container>
      <Helmet>
        <title>Nectarine - My Reviews</title>
      </Helmet>
      <Row>
        <Col xl="10" md="12" className="review-section">
          {showSavedMessage ? (
            <Alert className="saved-alert">
              <strong>Thank you!</strong> Your review has been saved!
            </Alert>
          ) : (
            <></>
          )}

          {advisorBeingReviewed ? (
            <ReviewForm
              advisor={advisorBeingReviewed}
              consumer={user}
              review={review!}
              onSuccessfulSave={showSuccess}
              onCancel={() => setAdvisorBeingReviewed(null)}
              isNonAuthenticatedReview={false}
            />
          ) : (
            <></>
          )}

          {advisorsToReview && advisorsToReview.length > 0 && !advisorBeingReviewed ? (
            <div>
              {advisorsToReview.map((advisor) => (
                <Row key={advisor.id} className="review-reminder">
                  <Col lg="3" className="text-center">
                    <img
                      src={advisor.profilePicUrl}
                      className="circle-image headshot"
                      alt={advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}
                    />
                  </Col>
                  <Col xl="7" lg="9" className="text-center">
                    <h2>Reminder to leave a review</h2>

                    <p>How was your experience with {advisor.firstName + ' ' + advisor.lastName + ', ' + advisor.certifications}?</p>
                    <Button
                      color="primary"
                      size="lg"
                      onClick={() => {
                        setReview((currReview) => {
                          return { ...currReview!, reviewee: advisor };
                        });
                        setAdvisorBeingReviewed(advisor);
                      }}
                    >
                      Leave a Review!
                    </Button>
                  </Col>
                  <Col xl="2" lg="0"></Col>
                </Row>
              ))}
            </div>
          ) : (
            <></>
          )}

          {!reviews ? <LoadingSpinner message="Loading..." /> : <></>}

          {reviews && reviews.length > 0 && !advisorBeingReviewed ? (
            <div>
              <h1>My Reviews</h1>

              {reviews.map((review) => (
                <Row key={review.revieweeId} className="my-review-row">
                  <Col md="3" className="text-center">
                    <img src={review.revieweeProfilePicUrl} className="circle-image headshot" alt={getUserName(review)} />
                  </Col>
                  <Col md="9">
                    <h2>{getUserName(review)}</h2>
                    <div className="review-ratings review-ratings-display">
                      <i className={review.rating >= 1 ? 'fa-star fa-solid rating-' + review.rating : 'fa-star fa-solid'}></i>
                      <i className={review.rating >= 2 ? 'fa-star fa-solid rating-' + review.rating : 'fa-star fa-solid'}></i>
                      <i className={review.rating >= 3 ? 'fa-star fa-solid rating-' + review.rating : 'fa-star fa-solid'}></i>
                      <i className={review.rating >= 4 ? 'fa-star fa-solid rating-' + review.rating : 'fa-star fa-solid'}></i>
                      <i className={review.rating >= 5 ? 'fa-star fa-solid rating-' + review.rating : 'fa-star fa-solid'}></i>
                    </div>
                    <h3>{review.title}</h3>
                    <p>{review.body}</p>
                    <div className="review-footer">
                      <div className="reviewer-name">{review.displayName}</div>
                      <div className="review-date">{DateHelper.printMonthYearLong(review.modifiedAt)}</div>
                    </div>
                    <p>
                      <Button size="sm" color="primary" onClick={(e) => onEditReview(e, review)}>
                        Edit this review
                      </Button>
                    </p>
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <></>
          )}

          {reviews && reviews.length === 0 && !advisorBeingReviewed ? (
            <div>
              <h1>My Reviews</h1>
              <p>You have not yet left any reviews.</p>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};
