import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class AffiliatesApiService extends ApiServiceBase {
  private static className = 'AffiliatesApiService';

  public static async getDashboardData(): Promise<Affiliates_DashboardPage_ViewModel> {
    try {
      const result = await this.makeApiCall<Affiliates_DashboardPage_ViewModel>(() => HttpHelper.get(`api/affiliate/dashboard`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getDashboardData()`, 'An error occurred while loading dashboard data.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAffiliateInfo(slug: string): Promise<User_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel>(() => HttpHelper.get(`api/home/affiliate/${slug}`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAffiliateInfo()`, 'An error occurred while loading affiliate info.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
