//@ts-check
﻿import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';

export class AdminCreateRole extends Component {
  static displayName = AdminCreateRole.name;

  constructor(props) {
    super(props);
    this.state = {
      redirectToAdminList: false,
      role: null
    };
  }

  onSubmit = async (event) => {
    event.preventDefault();

    // console.log('onSubmit');

    // extract form data
    const formdata = new FormData(event.target);

    // convert FormData to json object
    // SOURCE: https://stackoverflow.com/a/46774073
    const formItem = {};
    formdata.forEach(function (value, prop) {
      formItem[prop] = value;
    });

    // console.log('json', formItem);

    fetch('/api/admin/roles', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formItem)
    })
      .then(() => {
        // console.log('response', response);
        //response.json()
      })
      .then(() => {
        // console.log('End addItem');
        this.setState(() => ({ redirectToAdminList: true }));
      })
      .catch((error) => alert('Unable to add item.', error));

    return false;
  };

  render() {
    if (this.state.redirectToAdminList) {
      return <Navigate to={`/${ApplicationRoutes.AdminRoutes.RolesRoutes.Root_Full}`} />;
    }

    return (
      <Container>
        <Helmet>
          <title>Admin / Create Roles</title>
        </Helmet>
        <Row md="2" sm="1" xs="1">
          <Col>
            <h1>Create New Role</h1>

            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="role">Role Name</Label>
                <Input id="name" name="Name" placeholder="e.g. Admin, Consumer, Advisor, etc..." />
              </FormGroup>
              <Button color="primary">Create New Role</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
