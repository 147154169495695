import React from 'react';
import { Container } from 'reactstrap';
import LoadingSpinner from './../LoadingAnimations/LoadingSpinner/LoadingSpinner';
import './../Pages/Layout/PageLayout/PageLayout.scss';

export const LoadingBlank: React.FC = () => {
  return (
    <div>
      <Container tag="main">
        <LoadingSpinner />
      </Container>
    </div>
  );
};
