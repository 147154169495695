const accountRoot = 'account';
const adminRoot = 'admin';
const authenticationRoot = 'authentication';
const dashboardRoot = 'dashboard';
const servicesRoot = 'services';

const AppSettings = {
  ApplicationName: 'Nectarine',
  PrivacyPolicyUrl: 'https://s3.amazonaws.com/files.hellonectarine.com/SC8aOavKzk6B2I4Mzd8yvw-nectarine-privacy-policy.pdf',
  NectarineADV2A: 'https://s3.amazonaws.com/files.hellonectarine.com/7jXr8nRu0Ot7MGI28ldg-nectarine-adv-part-2a.pdf',
  NectarineCRS: 'https://s3.amazonaws.com/files.hellonectarine.com/MsArmVi5IkGYOOVyN8jYzQ-nectarine-form-crs.pdf'
};

const RecordingStatus = {
  Processing: 'Processing',
  SavedToS3: 'SavedToS3',
  SavedToS3AndDeletedFromZoom: 'SavedToS3AndDeletedFromZoom',
  Error: 'Error'
};

const UserLoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register'
};

const UserLogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

const ApplicationRoutes = {
  About: 'about',
  Advisor: 'advisor',
  Advisors: 'advisors',
  Affiliates: 'affiliates',
  Apply: 'apply',
  Booked: 'booked',
  Contact: 'contact',
  Error: 'error',
  FlatFeeFinancialPlanners: 'flat-fee-financial-planners',
  HourlyFinancialAdvisors: 'hourly-financial-advisors',
  LeaveAReview: 'leaveareview',
  NonUS: 'non-us',
  Privacy: 'privacy',
  Recruit: 'recruit',
  Register: 'register',
  Register_Affiliate: 'register?affiliate=true',
  Root: '/',
  Services: 'services',
  Terms: 'terms',
  ThePlan: 'the-plan',
  ThePlan_Signup: 'https://hellonectarine.typeform.com/theplansignup',
  ThePlan_Terms: 'theplan-terms',
  AdminRoutes: {
    Root: adminRoot,
    AdvisorAttributes: 'advisorattributes',
    AdvisorAttributes_Full: `${adminRoot}/advisorattributes`,
    AdvisorPayments: 'advisorpayments',
    AdvisorPayments_Full: `${adminRoot}/advisorpayments`,
    AffiliatePayments: 'affiliatepayments',
    AffiliatePayments_Full: `${adminRoot}/affiliatepayments`,
    Documents: 'documents',
    Documents_Full: `${adminRoot}/documents`,
    Events: 'events',
    Events_Full: `${adminRoot}/events`,
    IntakeForms: 'intakeforms',
    IntakeForms_Full: `${adminRoot}/intakeforms`,
    Recordings: 'recordings',
    Recordings_Full: `${adminRoot}/recordings`,
    Reports: 'reports',
    Reports_Full: `${adminRoot}/reports`,
    Reviews: 'reviews',
    Reviews_Full: `${adminRoot}/reviews`,
    RolesRoutes: {
      Root: 'roles',
      Root_Full: `${adminRoot}/roles`,
      Create: 'create',
      Create_Full: `${adminRoot}/roles/create`
    },
    Search_Full: `${adminRoot}/search`,
    Search: 'search',
    UserAgreements: 'useragreements',
    UserAgreements_Full: `${adminRoot}/useragreements`,
    Users_Full: `${adminRoot}/users`,
    Users: 'users'
  },
  AdvisorAccountRoutes: {
    Root: dashboardRoot,
    Client: 'client',
    Client_Full: `${dashboardRoot}/client`,
    Compliance: `compliance`,
    Compliance_Full: `${dashboardRoot}/compliance`,
    Payments: 'payments',
    Payments_Full: `${dashboardRoot}/payments`,
    Profile: 'profile',
    Profile_Full: `${dashboardRoot}/profile`,
    Reviews: 'reviews',
    Reviews_Full: `${dashboardRoot}/reviews`
  },
  AuthenticationRoutes: {
    Root: authenticationRoot,
    ApiAuthorizationClientConfigurationUrl: `_configuration/${AppSettings.ApplicationName}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    Login: `${authenticationRoot}/login`,
    LoginDefaultRedirectPath: '/',
    LoginFailed: `${authenticationRoot}/${UserLoginActions.LoginFailed}`,
    LoginCallback: `${authenticationRoot}/${UserLoginActions.LoginCallback}`,
    Logout: `${authenticationRoot}/logout`,
    LoggedOut: `${authenticationRoot}/${UserLogoutActions.LoggedOut}`,
    LogOutCallback: `${authenticationRoot}/${UserLogoutActions.LogoutCallback}`,
    Profile: `${authenticationRoot}/${UserLoginActions.Profile}`
  },
  ClientAccountRoutes: {
    Root: accountRoot,
    Advisors: 'advisors',
    Manage_Full: 'Identity/Account/Manage',
    Reviews: 'reviews',
    Reviews_Full: `${accountRoot}/reviews`,
    Share: 'share',
    Share_Full: `${accountRoot}/share`
  },
  SeoLandingPagesRoutes: {
    Root: servicesRoot,
    FeeOnlyFiduciaries: 'fee-only-fiduciaries',
    FeeOnlyFiduciaries_Full: `${servicesRoot}/fee-only-fiduciaries`,
    FiduciaryCFPS: 'fiduciary-cfps',
    FiduciaryCFPS_Full: `${servicesRoot}/fiduciary-cfps`,
    FlatRateFinancialPlanners: 'flat-rate-financial-planners',
    FlatRateFinancialPlanners_Full: `${servicesRoot}/flat-rate-financial-planners`,
    IndependentFinancialAdvisors: 'independent-financial-advisors',
    IndependentFinancialAdvisors_Full: `${servicesRoot}/independent-financial-advisors`
  }
};

const UserRoles = {
  Admin: 'Admin',
  Advisor: 'Advisor',
  Affiliate: 'Affiliate',
  Consumer: 'Consumer'
};

const CommonApiBadRequestErrors = {
  NotLoggedIn: 'notLoggedIn'
};

const CommonErrorMessages = {
  General: `We're sorry, but an error occurred.`,
  NotLoggedIn: 'You must be logged in to continue.'
};

const HTTPStatuses = {
  Ok: 200,
  NoContent: 204,
  BadRequest: 400,
  NotFound: 404
};

const CommonQueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

enum SortOptions {
  Newest = 1,
  Oldest = 2,
  Highest = 3,
  Lowest = 4
}

export {
  AppSettings,
  ApplicationRoutes,
  CommonApiBadRequestErrors,
  CommonErrorMessages,
  CommonQueryParameterNames,
  HTTPStatuses,
  RecordingStatus,
  SortOptions,
  UserLoginActions,
  UserLogoutActions,
  UserRoles
};
