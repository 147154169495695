import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import LoadingDots from '../LoadingAnimations/LoadingDots/LoadingDots';
import './FilterResultsModal.scss';

interface FilterResultsModalProps {
  advisors: Advisor_BasicDetails_ViewModel[];
  selectedAttributes: string[];
  show: boolean;

  toggle: () => void;
  onSave: (event: React.FormEvent, incomingAdvisors: Advisor_BasicDetails_ViewModel[], incomingAttributes: string[]) => boolean;

  getMatchingAdvisors: (selectedAttributes: string[], advisorsList: Advisor_BasicDetails_ViewModel[]) => Advisor_BasicDetails_ViewModel[];

  areAdvisorAttributesAPerfectMatchWithFilteredAttributes: (
    advisor: Advisor_BasicDetails_ViewModel,
    selectedAttributes: string[]
  ) => boolean;
}

export const FilterResultsModal: React.FC<FilterResultsModalProps> = (props: FilterResultsModalProps) => {
  const [saveFilterLoading] = useState<boolean>(false);
  const [errorSection, setErrorSection] = useState<{ show: boolean; text: string | null }>({ show: false, text: null });
  const [iCanHelpYouWith, setICanHelpYouWith] = useState<Advisor_Attribute_ViewModel[]>([]);
  const [iEnjoyWorkingWith, setIEnjoyWorkingWith] = useState<Advisor_Attribute_ViewModel[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>(props.selectedAttributes ?? []);
  const [matchingAdvisors, setMatchingAdvisors] = useState<Advisor_BasicDetails_ViewModel[]>([]);

  useEffect(() => {
    populateAvailableFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMatchingAdvisors(props.getMatchingAdvisors(selectedAttributes, props.advisors));
  }, [props, selectedAttributes]);

  useEffect(() => {
    setSelectedAttributes(props.selectedAttributes);
  }, [props.selectedAttributes]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    hideError();

    const { value, checked } = event.target;

    if (checked) {
      setSelectedAttributes((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedAttributes((prevSelected) => prevSelected.filter((att) => att !== value));
    }
  };

  function clearFilters(e: React.MouseEvent) {
    e.preventDefault();
    setSelectedAttributes([]);

    return false;
  }

  function populateAvailableFilters() {
    //Go through each advisor and populate the iCanHelpYouWith array to include any attribute that any advisor has
    const allICanHelpYouWithSet = new Set();
    const allICanHelpYouWith: Advisor_Attribute_ViewModel[] = [];
    const allIEnjoyWorkingWithSet = new Set();
    const allIEnjoyWorkingWith: Advisor_Attribute_ViewModel[] = [];

    // Iterate through advisors and their items
    props.advisors.forEach((advisor) => {
      advisor.iCanHelpYouWith.forEach((item) => {
        if (!allICanHelpYouWithSet.has(item.slug)) {
          allICanHelpYouWithSet.add(item.slug);
          allICanHelpYouWith.push(item);
        }
      });

      advisor.iEnjoyWorkingWith.forEach((item) => {
        if (!allIEnjoyWorkingWithSet.has(item.slug)) {
          allIEnjoyWorkingWithSet.add(item.slug);
          allIEnjoyWorkingWith.push(item);
        }
      });
    });

    setICanHelpYouWith(allICanHelpYouWith);
    setIEnjoyWorkingWith(allIEnjoyWorkingWith);
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  function getCountOfAdvisorsWhoMatchFiltersPerfectly() {
    return matchingAdvisors.filter((v) => props.areAdvisorAttributesAPerfectMatchWithFilteredAttributes(v, selectedAttributes)).length;
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <Form onSubmit={(e) => props.onSave(e, matchingAdvisors, selectedAttributes)}>
        <ModalHeader toggle={props.toggle}>Filters</ModalHeader>
        <ModalBody>
          {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}

          <h2>What do you need help with?</h2>

          {iCanHelpYouWith.length ? (
            <Row>
              {sortBy(iCanHelpYouWith, (v) => v.title).map((item) => (
                <Col key={item.slug} sm="6" xs="12">
                  <Input
                    type="checkbox"
                    id={item.slug}
                    value={item.slug}
                    checked={selectedAttributes.includes(item.slug)}
                    onChange={handleCheckboxChange}
                  />{' '}
                  <Label for={item.slug}>
                    <i className={'fa-light att-icon results-att ' + item.iconTag}></i> {item.title}
                  </Label>
                </Col>
              ))}
            </Row>
          ) : (
            <></>
          )}

          <h2>Show advisors who enjoy working with</h2>
          {iEnjoyWorkingWith.length ? (
            <Row>
              {sortBy(iEnjoyWorkingWith, (v) => v.title).map((item) => (
                <Col key={item.slug} sm="6" xs="12">
                  <Input
                    type="checkbox"
                    id={item.slug}
                    value={item.slug}
                    checked={selectedAttributes.includes(item.slug)}
                    onChange={handleCheckboxChange}
                  />{' '}
                  <Label for={item.slug}>
                    <i className={'fa-light att-icon results-att ' + item.iconTag}></i> {item.title}
                  </Label>
                </Col>
              ))}
            </Row>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter className="d-block">
          <div className="d-flex flex-column justify-content-end flex-lg-row">
            <div className="flex-grow-1 ms-2 mb-3 mb-lg-0">
              {selectedAttributes.length > 0 ? (
                <>
                  {matchingAdvisors && <strong>{matchingAdvisors.length}</strong>} Matching Advisor
                  {matchingAdvisors && matchingAdvisors.length != 1 && 's'}
                  {selectedAttributes.length > 1 ? (
                    <div className="perfect-filters-match-text">
                      {getCountOfAdvisorsWhoMatchFiltersPerfectly()} Perfect Match
                      {getCountOfAdvisorsWhoMatchFiltersPerfectly() === 1 ? '' : 'es'}
                    </div>
                  ) : (
                    <div className="perfect-filters-match-text">&nbsp;</div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex flex-nowrap">
              <Button color="light" onClick={(e) => clearFilters(e)} className="me-2">
                <i className="fa-duotone fa-eraser"></i> Clear Filters
              </Button>

              <Button color="primary" disabled={saveFilterLoading}>
                {saveFilterLoading ? (
                  <LoadingDots />
                ) : (
                  <div>
                    View {matchingAdvisors && <strong>{matchingAdvisors.length}</strong>} Advisor
                    {matchingAdvisors && matchingAdvisors.length != 1 && 's'}
                  </div>
                )}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
