import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Col, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../constants';
import logoImage from '../../img/nectarine-logo.png';
import AuthorizeService from '../api-authorization/AuthorizeService';
import ImpersonationLabel from '../ImpersonationLabel/ImpersonationLabel';
import './../../styles/Account.scss';
import './../../styles/Advisor.scss';

const AdvisorLayout: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User_BasicDetails_ViewModel | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const userViewModel = await AuthorizeService.populateActiveUser();
      setUser(userViewModel);
    };

    loadData();
  }, []);

  return (
    <div className="container-fluid">
      {user ? (
        <>
          <ImpersonationLabel user={user} />

          <Row>
            <Col className="account-menu col-3 d-none d-md-block p-0 bg-secondary p-3 min-vh-100">
              <NavbarBrand tag={Link} to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="logo mt-2" />
              </NavbarBrand>

              <ul className="navbar-nav flex-grow list-group mt-4">
                <NavItem>
                  <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`} end className="nav-link">
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Profile_Full}`} className="nav-link">
                    My Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Reviews_Full}`} className="nav-link">
                    Reviews
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Payments_Full}`} className="nav-link">
                    Payments
                  </NavLink>
                </NavItem>
                <NavItem>
                  <a href={`/${ApplicationRoutes.ClientAccountRoutes.Manage_Full}`} className="nav-link">
                    Account
                  </a>
                </NavItem>
                <NavItem className="user-section">
                  <a href={`/${ApplicationRoutes.ClientAccountRoutes.Manage_Full}`} className="nav-link">
                    Hi {user && user.firstName}!
                  </a>
                </NavItem>
              </ul>
            </Col>
            <Col className="col-12 col-md-9 p-0">
              <Navbar color="light" light expand="md" className="bg-secondary d-md-none mobile-menu">
                <NavbarBrand tag={Link} to={ApplicationRoutes.Root}>
                  <img src={logoImage} alt="Nectarine" className="mobile-logo mt-2" />
                </NavbarBrand>
                <NavbarToggler
                  onClick={() => {
                    setIsOpen((v) => !v);
                  }}
                />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <NavItem>
                      <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`} end className="nav-link">
                        Home8788
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Profile_Full}`} className="nav-link">
                        My Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Reviews_Full}`} className="nav-link">
                        Reviews
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to={`/${ApplicationRoutes.AdvisorAccountRoutes.Payments_Full}`} className="nav-link">
                        Payments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <a href="/Identity/Account/Manage" className="nav-link">
                        Account
                      </a>
                    </NavItem>
                    <NavItem className="user-section">
                      <a href="/Identity/Account/Manage" className="nav-link">
                        Hi {user && user.firstName}!
                      </a>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>

              <main className="px-4">
                <Outlet context={[user]} />
              </main>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdvisorLayout;
