//@ts-check
﻿import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

export const ClientAccountAdvisors = () => {
  return (
    <Container>
      <Helmet>
        <title>Nectarine - My Account</title>
      </Helmet>
    </Container>
  );
};
