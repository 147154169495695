//@ts-check
﻿import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import LoadingSpinner from '../../../../LoadingAnimations/LoadingSpinner/LoadingSpinner';

export const AdvisorAccountReviews = () => {
  const [reviews, setReviews] = useState(null);

  React.useEffect(() => {
    const loadData = async () => {
      const reviews = await AdvisorsApiService.getAdvisorReviews();
      setReviews(reviews);
    };

    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Nectarine - My Account</title>
      </Helmet>
      <Row md="12">
        <Col>
          <h1 className="mb-4">My Reviews</h1>

          {!reviews && <LoadingSpinner message="Loading..." />}
          {reviews && reviews.length == 0 && <p>You have not yet received any reviews. When you do they'll appear here!</p>}
          {reviews && reviews.length > 0 && (
            <div>
              <p>
                You have received <strong>{reviews.length}</strong> reviews with an average rating of{' '}
                <strong>{(reviews.reduce((a, b) => a + b.rating, 0) / reviews.length).toFixed(2)}</strong> stars.
              </p>

              {reviews
                .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                .map((review) => (
                  <div key={review.id} className="details-review">
                    {Array.from({ length: review.rating }, (v, i) => (
                      <i key={v} className="fa-star fa-solid att-icon"></i>
                    ))}
                    <h3>{review.title}</h3>
                    <p>{review.body}</p>

                    <div className="review-footer">
                      <div className="reviewer-name">{review.displayName}</div>
                      <div className="review-date">{DateHelper.printMonthYearLong(review.modifiedAt)}</div>
                    </div>

                    {review.privateComments ? (
                      <div className="my-2">
                        <strong>Private Notes From {review.displayName}</strong>
                        <div>{review.privateComments}</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
