import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import DateHelper from '../../../Helpers/DateHelper';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

interface AdminEventsTableProps {
  events: Admin_Event_ViewModel[];
  onDelete: (event: Admin_Event_ViewModel) => void;
}

export const AdminEventsTable: React.FC<AdminEventsTableProps> = (props: AdminEventsTableProps) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Advisor</th>
          <th>Consumer</th>
          <th>
            <i className="fa-duotone fa-peach light-blue"></i>
          </th>
          <th>State</th>
          <th>Intake</th>
          <th>Created At</th>
          <th>Start Time</th>
          <th>Status</th>
          <th>Links</th>
          <th>Paid</th>
          <th className="text-danger">Danger</th>
        </tr>
      </thead>
      <tbody>
        {props.events.map((event) => (
          <tr key={event.id}>
            <td>
              <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.advisorId}/edit`}>{event.advisorFullName}</Link>

              {!event.advisorFullName && <span>{event.advisorEmail}</span>}

              {event.advisorId && <AdminImpersonateLink userId={event.advisorId} />}
            </td>

            <td>
              <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/edit`}>{event.consumerFullName}</Link>
              {!event.consumerFullName && <span>{event.consumerEmail}</span>}

              {event.consumerId && <AdminImpersonateLink userId={event.consumerId} />}
            </td>
            <td>{event.isNectarine && <i className="fa-duotone fa-peach light-blue"></i>}</td>
            <td>{event.consumerState}</td>
            <td>
              {event.intakeForm && <i className="fa-duotone fa-check-to-slot att-icon"></i>}
              {!event.intakeForm && <i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i>}
            </td>
            <td>
              <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${event.id}/edit`}>
                {DateHelper.mediumDateFormat(event.createdAt)}
              </Link>
            </td>
            <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
            <td>{event.status}</td>

            <td>
              <a href={event.location}>Zoom</a> <a href={event.rescheduleUrl}>Reschedule</a> <a href={event.cancelUrl}>Cancel</a>{' '}
              {event.consumerId && event.advisorId && <a href={'/leaveareview/' + event.advisorId + '/' + event.consumerId}>Review Page</a>}
            </td>

            <td>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: event.paymentCurrency ? event.paymentCurrency : 'USD',
                maximumFractionDigits: 0
              }).format(event.paymentAmount)}
              {!event.paymentSuccessful && event.paymentAmount > 0 && (
                <span className="text-danger">
                  {' '}
                  <strong>FAILED!</strong>
                </span>
              )}
            </td>
            <td>
              <Button
                color="danger"
                onClick={(e) => {
                  e.preventDefault();
                  props.onDelete(event);
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
