export default class DateHelper {
  //August 22, 2023 at 1:11 PM PDT
  public static longDateFormat(input: Date): string {
    return new Date(input)
      .toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short'
      })
      .replace(/(\d)(st|nd|rd|th)/, '$1<sup>$2</sup>');
  }

  //8/24/2023 9:16pm
  public static mediumDateFormat(input: Date): string {
    const date = new Date(input);
    const options: Intl.DateTimeFormatOptions = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };

    return date.toLocaleString('en-US', options);
  }

  //8/24/2023
  public static shortDateFormat(input: Date): string {
    const date = new Date(input);
    const options: Intl.DateTimeFormatOptions = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    };

    return date.toLocaleString('en-US', options);
  }

  //Same as medium, but replaces date with "Today" or "Tomorrow"
  public static casualDateFormat(input: Date): string {
    const date = new Date(input);

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today, ' + date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    }

    if (date.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow, ' + date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    }

    const options: Intl.DateTimeFormatOptions = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };

    return date.toLocaleString('en-US', options);
  }

  public static getFullYearsBetweenDates(startDate: Date, endDate: Date): number {
    // Calculate the difference in years
    const yearDifference = endDate.getFullYear() - startDate.getFullYear();

    // Check if the end date has not occurred yet in the current year
    if (
      endDate.getMonth() < startDate.getMonth() ||
      (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())
    ) {
      return yearDifference - 1;
    } else {
      return yearDifference;
    }
  }

  public static isValidDate(input: string) {
    // Attempt to create a Date object from the provided string
    const dateObject = new Date(input);

    // Check if the created object is a valid date
    return !isNaN(dateObject.getTime());
  }

  public static printYearRange(startDateText: Date, endDateText: Date) {
    const startDate = new Date(startDateText);
    const endDate = new Date(endDateText);
    const minDate = new Date(0);
    const today = new Date();

    if (startDate > minDate && endDate > minDate && endDate < today) {
      //Return full year start and end
      return startDate.getUTCFullYear() + ' - ' + endDate.getUTCFullYear();
    }
    if (startDate > minDate) {
      return startDate.getUTCFullYear() + ' - Present';
    }

    return '';
  }

  public static printMonthYearLong(input: Date) {
    const dateObject = new Date(input);
    const formattedDate = dateObject.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    return formattedDate;
  }
}
